@layer reset, primeng;

@layer reset {
  /* Normalize box model  */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    text-size-adjust: none;
  }

  /* Remove mobile tap highlight */
  * {
    -webkit-tap-highlight-color: transparent;
  }

  /* Remove default margin */
  html, body, h1, h2, h3, h4, h5, h6, p, ul, ol, li {
    margin: 0;
  }

  /* Add accessible line-height */
  body {
    line-height: 1.5;
  }

  html, body {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  @supports (height: 100dvh) {
    html, body {
      width: 100dvw;
      height: 100dvh;
    }
  }

  /* Improve text rendering */
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* Improve media defaults */
  audio,
  canvas,
  img,
  video {
    display: block;
    max-width: 100%;
  }

  /* Remove built-in form typography styles */
  input,
  button,
  select,
  textarea {
    font: inherit;
  }

  /* Avoid text overflows */
  a {
    text-decoration: none;
  }

  /* Reset list styles  */
  ul,
  ol {
    list-style: none;
    padding: 0;
  }

  /*  Set consistent base font size */
  html {
    font-size: 16px;
  }
}
