@import '../breakpoints';
@import '../_shared';

p-dialog,
.p-dialog,
.p-dialog-mask {
  &.dialog-primary {
    .p-dialog {
      border-radius: 12px;
    }

    .p-dialog-header {
      padding: 24px;
      border-bottom: 1px solid var(--color-gray-300);
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    .p-dialog-content {
      padding: 0;
      border-top-width: 1px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }

    .p-dialog-header-icons {
      width: 24px;
      height: 24px;

      button {
        width: 24px;
        height: 24px;
      }
    }

    .footer {
      padding: 24px;

      &.border-top {
        border-top: 1px solid var(--color-gray-300);
      }
    }

    .header {
      .header-title {
        @include switzerFont;
        line-height: 24px;
        font-size: 18px;
        font-weight: 600;
        color: var(--color-gray-900);
      }
    }

    .caption {
      color: var(--color-gray-900);
    }

    .title {
      color: var(--color-gray-900);
    }

    .subtitle {
      color: var(--color-gray-500);
    }

    &.mobile-stick-to-bottom {
      .p-dialog {
        position: absolute;
        bottom: 0;
        max-height: 90%;
        height: fit-content;
        width: 100%;

        .p-dialog-content {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }

    &.width-unset-dynamic-height {
      .p-dialog {
        width: unset;
        max-width: calc(100% - 32px);
        max-height: calc(100% - 32px);
      }
    }

    &.width-unset-full-height {
      .p-dialog {
        max-height: unset;
        width: unset;
        max-width: calc(100% - 32px);
        height: calc(100% - 32px);
      }
    }

    &.full {
      .p-dialog {
        max-height: unset;
        width: calc(100% - 16px);
        height: calc(100% - 16px);
      }
    }

    &.extra-large {
      .p-dialog {
        width: 608px;
      }
    }

    &.large {
      .p-dialog {
        width: 430px;
      }
    }

    &.medium {
      .p-dialog {
        width: 380px;
      }
    }

    &.small {
      .p-dialog {
        width: 380px;
      }
    }

    &.w420 {
      .p-dialog {
        width: 420px;
      }
    }

    &.w1400 {
      .p-dialog {
        width: 1400px;
      }
    }

    &.headless {
      .p-dialog-header {
        display: none;
      }

      .p-dialog-content {
        padding: 0;
        border-radius: 12px;
      }
    }

    .p-dialog-title {
      @include switzerFont();
      line-height: 24px;
      font-size: 18px;
      font-weight: 600;
      color: var(--color-gray-900);
    }
  }

  &.dialog-content-flex {
    .p-dialog-content {
      display: flex;
      overflow: hidden;
    }

    &.f-d-col {
      .p-dialog-content {
        display: flex;
        overflow: hidden;
        flex-direction: column;
      }
    }
  }

  &.no-controls {
    .p-dialog-header-icons {
      display: none;
    }
  }
}

// For custom dialog component
.p-dialog.unset-default-style {
  box-shadow: unset;

  .p-dialog-content {
    background: transparent;
    border: unset;
    padding: unset;
  }
}

.p-dialog {
  &.full-screen-mode {
    max-height: unset;
    max-width: unset;
    width: 100%;
    height: 100%;

    .p-dialog-header {
      border-radius: 0;
    }
    .p-dialog-content {
      border-radius: 0;
    }
  }

  &.desktop-min-width {
    &--700 {
      @extend .desktop-min-width;
      min-width: 700px;
    }
  }

  &.width-unset-dynamic-height {
    width: unset;
    max-width: calc(100% - 32px);
    max-height: calc(100% - 32px);
  }
}

p-dynamicdialog {
  &:has(.no-header) {
    .p-dialog-header {
      display: none;
    }

    .p-dialog-content {
      padding: 0;
      border-radius: 12px;
    }
  }
}

@include mobile() {
  .p-dialog {
    &.mobile-custom-show-animation {
      position: fixed;
      bottom: -100%;
      animation: slideIn 0.2s ease-in-out forwards;
    }

    &.mobile-stick-to-bottom {
      position: absolute;
      bottom: 0;
      max-height: 90%;
      height: fit-content;
      width: 100%;

      .p-dialog-content {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &.desktop-min-width {
      min-width: unset;
    }
  }
}

@keyframes slideIn {
  from {
    bottom: -100%;
  }
  to {
    bottom: 0px;
  }
}

@keyframes slideOut {
  from {
    bottom: 0px;
  }
  to {
    bottom: -100%;
  }
}
