@import '../breakpoints';
@import '../_shared';

p-menubar {
  .p-menubar {
    padding: 7px;
    background-color: white;
    border-radius: 0;
    border-bottom-color: var(--color-gray-200);
  }
}
