@import 'libs/web/src/ui-kit/styles/breakpoints.scss';

.p-datepicker {
  @include switzerFont();
  padding: 0;
  border-radius: 0;
  border: 0px;

  .p-datepicker-group {
    padding: 20px 24px;
  }

  .p-datepicker-header {
    padding: 0;
  }

  .p-datepicker-title {
    button {
      color: var(--color-gray-900);
    }

    button:nth-child(1) {
      padding-right: 0;
    }
    button:nth-child(2) {
      padding-left: 0;
    }
  }

  .p-datepicker-header {
    border: unset;
  }

  .p-datepicker-calendar-container table {
    thead,
    tbody {
      th,
      td {
        width: 40px;
        height: 40px;
        padding: 2px 0;
      }

      span {
        font-size: 14px;
        line-height: 20px;
        color: var(--color-gray-700);
      }
    }

    thead span {
      font-weight: 600;
    }

    tbody span {
      font-weight: 400;
    }

    td.range-end,
    td.range-start,
    td.range-entry {
      span {
        background-color: transparent;
      }
    }

    td.range-entry {
      border-radius: 0;
      background-color: var(--color-primary-50);
      &:first-child {
        border-radius: 50% 0 0 50%;
      }
      &:last-child {
        border-radius: 0 50% 50% 0;
      }
    }

    td.range-end,
    td.range-start {
      background-color: var(--color-primary-50);
      span {
        color: white;
        background-color: var(--color-primary-500);
      }
    }
    td.range-start {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }
    td.range-end {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }

    td.p-datepicker-today > span:not(.p-highlight) {
      background-color: var(--color-gray-100);
    }
  }
}


@include mobile {
  .p-datepicker {
    .p-datepicker-group {
      padding: 0;
    }
  }

  p-calendar {
    width: 100%;
    padding: 0 16px;
  }

  .p-calendar {
    width: 100%;
  }
}
