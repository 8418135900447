@import 'breakpoints';

.round-icon-marker-wrapper {
  --arrow-color: #12b76a;
  --heading-color: var(--color-gray-900);
  --heading-background: rgba(14, 24, 41, 0.08);

  display: block;
  width: 100%;
  height: 100%;

  &.light-mode {
    --heading-color: var(--color-gray-0);
    --heading-background: rgba(255, 255, 255, 0.34);
  }

  &.status-invalid {
    --arrow-color: var(--color-gray-500);

    .status-icon .icon-idling {
      --icon-color: var(--color-gray-500);
      --border-color: var(--color-white);
    }
  }

  .arrow {
    pointer-events: none;
    height: 110px;
    width: 110px;
    position: absolute;
    left: 0;
    top: 0;
    // transition: transform ease-in-out 0.5s;
    transform: translateX(-30%) translateY(-30%) rotate(var(--direction-angle-deg, 0));

    &.inverse {
      svg {
        top: -5px;
        transform: translateX(45%) rotate(-10deg);
      }
    }

    svg {
      position: absolute;
      top: 0;
      right: 50%;
      transform: translateX(45%) rotate(170deg);
    }
  }

  .heading {
    position: absolute;
    top: var(--heading-offset, -8px);
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
    border-radius: 40px;
    width: fit-content;
    text-wrap: nowrap;
    white-space: nowrap;
    padding: 2px 10px;
    color: var(--heading-color);
    background: var(--heading-background);
    backdrop-filter: blur(1px);
    max-width: 190px;
    overflow: hidden;
    line-height: 24px;
  }

  .status-icon {
    position: absolute;
    right: -9px;
    bottom: -9px;

    .icon-idling {
      --icon-color: #c3505d;
      --border-color: var(--color-white);
    }

    &.static {
      right: -4px;
      bottom: -9px;
    }
  }

  $border-radius: 4px;
  .shadow {
    display: block;
    content: '';
    position: absolute;
    height: calc(100% + $border-radius * 2);
    width: calc(100% + $border-radius * 2);
    left: -$border-radius;
    top: -$border-radius;
    border: 4px solid var(--color-gray-0);
    border-radius: 50%;
    -webkit-box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, .4);
    -moz-box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, .4);
    box-shadow: 0 0 4px -1px #0006;

    &.vehicle {
      -webkit-box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, .4);
      -moz-box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, .4);
      box-shadow: 0 0 9px -1px #0006;
    }
  }

  .marker-icon {
    height: 44px;
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--icon-color);
    border-radius: 50%;
  }

  .icon-outer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background: var(--color-gray-0);
    -webkit-box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.4);
  }

  .icon-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    color: var(--color-primary-500);
  }
}

.geofence-label {
  border-radius: 40px;
  color: white;
  background: var(--color-primary-400);
  padding: 6px 12px;
}

app-split-view-panel {
  app-map {
    .geofence-control {
      bottom: 36px !important;
    }
    .maplibregl-ctrl-bottom-right {
      bottom: 20px !important;
    }
  }
}

@include mobile {
  .heading {
    font-size: 16px;
    padding: 4px 12px;
    font-weight: 500;
  }
}
