@import '../breakpoints';
@import '../_shared';

p-button {
  @include switzerFont;

  --p-button__height: 40px;
  --p-button__badge-height: 20px;

  --p-button__color: var(--color-gray-0);
  --p-button__bg-color: var(--color-primary-500);
  --p-button__border-color: var(--color-primary-500);

  --p-button__hover-bg-color: var(--color-primary-600);
  --p-button__hover-border-color: var(--color-primary-600);

  --p-button__active-bg-color: var(--color-primary-700);
  --p-button__active-border-color: var(--color-primary-700);

  --p-button__badge-text-color: var(--color-primary-500);
  --p-button__badge-bg-color: var(--color-gray-0);

  --p-button__border-radius: 8px;
  --p-button__padding: 0 12px;
  --p-button__icon-only-font-size: 16px;

  --p-button__icon-color: var(--color-primary-500);
  --icon-color: var(--color-gray-0);

  &.btn-compact {
    .p-button {
      width: 40px;
      height: 40px;
    }
  }

  .p-button:not(.p-button-link) {
    justify-content: center;
    padding: var(--p-button__padding);
    height: var(--p-button__height);
    color: var(--p-button__color);
    background: var(--p-button__bg-color);
    border-radius: var(--p-button__border-radius);
    border: 1px solid var(--p-button__border-color);

    &:not(.p-button-icon-only) {
      .icon,
      .pi {
        margin-right: 8px;

        &.right {
          margin-left: 8px;
          margin-right: 0;
        }
      }
    }

    &:hover {
      background: var(--p-button__hover-bg-color);
      border-color: var(--p-button__hover-border-color);
    }

    &:active {
      background: var(--p-button__active-bg-color);
      border-color: var(--p-button__active-border-color);
    }

    &:focus {
      box-shadow: none;
    }
  }

  .p-button-link {
    padding: 0;
    font-weight: 600;
  }

  &.no-text-wrap {
    button {
      white-space: nowrap;
    }
  }

  &.h {
    &--34 {
      @extend .h;
      --p-button__height: 34px;
      --p-button__padding: 0 8px;
      --p-button__border-radius: 6px;
      --p-button__badge-height: 18px;
    }

    &--38 {
      @extend .h;
      --p-button__height: 38px;
      --p-button__padding: 0 12px;
      --p-button__border-radius: 6px;
      --p-button__badge-height: 18px;
    }

    &--40 {
      @extend .h;
      --p-button__height: 40px;
      --p-button__padding: 0 12px;
      --p-button__border-radius: 6px;
      --p-button__badge-height: 20px;
    }

    &--42 {
      @extend .h;
      --p-button__height: 42px;
      --p-button__padding: 0 12px;
      --p-button__badge-height: 20px;
    }

    &--44 {
      @extend .h;
      --p-button__height: 44px;
      --p-button__padding: 0 12px;
      --p-button__badge-height: 20px;
    }

    &--48 {
      @extend .h;
      --p-button__height: 48px;
      --p-button__padding: 0 18px;
      --p-button__icon-only-font-size: 20px;
      --p-button__badge-height: 20px;
    }
  }

  &.w50 {
    width: 50%;

    button {
      width: 100%;
    }
  }

  &.w100 {
    width: 100%;

    button {
      width: 100%;
    }
  }

  &.w-grow {
    flex-grow: 1;

    button {
      width: 100%;
    }
  }

  &.p {
    &--8 {
      @extend .p;
      --p-button__padding: 0 8px;
    }

    &--10 {
      @extend .p;
      --p-button__padding: 0 10px;
    }

    &--12 {
      @extend .p;
      --p-button__padding: 0 12px;
    }

    &--16 {
      @extend .p;
      --p-button__padding: 0 16px;
    }

    &--18 {
      @extend .p;
      --p-button__padding: 0 18px;
    }

    &--24 {
      @extend .p;
      --p-button__padding: 0 24px;
    }
  }

  &.br {
    &--6 {
      @extend .br;
      --p-button__border-radius: 6px;
    }

    &--8 {
      @extend .br;
      --p-button__border-radius: 8px;
    }
  }

  &:has(.p-badge) {
    &:hover {
      --p-button__badge-text-color: var(--p-button__color);
    }

    &:active {
      --p-button__badge-text-color: var(--p-button__color);
    }

    &:has(.p-button-outlined) {
      .p-badge {
        background-color: var(--p-button__color);
        color: var(--color-gray-0);
      }
    }

    .p-badge {
      min-width: var(--p-button__badge-height);
      height: var(--p-button__badge-height);
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;

      padding: 0 4px;
      margin-left: 8px;
      font-size: 12px;

      background-color: var(--p-button__badge-bg-color);
      color: var(--p-button__badge-text-color);
      @include switzerFont;
    }

    &:has(.p-button-secondary) {
      --p-button__badge-bg-color: var(--p-button__color);
      --p-button__badge-text-color: var(--color-gray-0);
    }

    &:has(.p-button-warning) {
      --p-button__badge-bg-color: var(--p-button__color);
      --p-button__badge-text-color: var(--p-button__bg-color);
    }

    &:has(.p-button-danger) {
      --p-button__badge-bg-color: var(--p-button__color);
      --p-button__badge-text-color: var(--p-button__bg-color);
    }

    &:has(.p-button-success) {
      --p-button__badge-bg-color: var(--p-button__color);
      --p-button__badge-text-color: var(--p-button__bg-color);
    }
  }

  &:has(.p-button-secondary) {
    --p-button__color: var(--color-primary-500);
    --p-button__bg-color: var(--color-gray-0);
    --p-button__border-color: var(--color-gray-300);

    --p-button__hover-bg-color: var(--color-primary-100);
    --p-button__hover-border-color: var(--color-primary-300);

    --p-button__active-bg-color: var(--color-primary-200);
    --p-button__active-border-color: var(--color-primary-500);

    --icon-color: var(--p-button__icon-color);
  }

  &:has(.p-button-warning) {
    --p-button__color: var(--color-gray-0);
    --p-button__bg-color: var(--color-secondary-500);
    --p-button__border-color: var(--color-secondary-500);

    --p-button__hover-bg-color: var(--color-secondary-500);
    --p-button__hover-border-color: var(--color-secondary-500);

    --p-button__active-bg-color: var(--color-secondary-500);
    --p-button__active-border-color: var(--color-secondary-500);

    --p-button__icon-color: var(--color-secondary-500);
  }

  &:has(.p-button-danger) {
    --p-button__color: var(--color-gray-0);
    --p-button__bg-color: var(--color-alert-500);
    --p-button__border-color: var(--color-alert-500);

    --p-button__hover-bg-color: var(--color-alert-500);
    --p-button__hover-border-color: var(--color-alert-500);

    --p-button__active-bg-color: var(--color-alert-500);
    --p-button__active-border-color: var(--color-alert-500);

    --p-button__icon-color: var(--color-alert-500);
  }

  &:has(.p-button-success) {
    --p-button__color: var(--color-gray-0);
    --p-button__bg-color: var(--color-success-500);
    --p-button__border-color: var(--color-success-500);

    --p-button__hover-bg-color: var(--color-success-500);
    --p-button__hover-border-color: var(--color-success-500);

    --p-button__active-bg-color: var(--color-success-500);
    --p-button__active-border-color: var(--color-success-500);

    --p-button__icon-color: var(--color-success-500);
  }

  &:has(.p-button-outlined) {
    --p-button__color: var(--p-button__border-color);
    --p-button__bg-color: var(--color-gray-0);

    --p-button__hover-bg-color: var(--color-primary-100);
    --p-button__hover-border-color: var(--color-primary-500);

    --p-button__active-bg-color: var(--color-primary-200);
    --p-button__active-border-color: var(--color-primary-500);

    --icon-color: var(--p-button__icon-color);

    &.flat {
      --p-button__bg-color: var(--color-primary-50);
      --p-button__active-bg-color: var(--color-primary-100);
      --p-button__hover-bg-color: var(--p-button__bg-color);

      &.saturated {
        --p-button__bg-color: var(--color-primary-200);
        --p-button__active-bg-color: var(--color-primary-300);
        --p-button__hover-bg-color: var(--p-button__bg-color);
      }

      .p-button {
        border-width: 0;
        box-shadow: 0px 1px 2px 0px color(display-p3 0.0627 0.0941 0.1569 / 0.05);
      }

      .p-button-secondary {
        --p-button__bg-color: var(--color-gray-50);
        --p-button__active-bg-color: var(--color-gray-100);
        --p-button__hover-bg-color: var(--p-button__bg-color);
      }

      .p-button-success {
        --p-button__bg-color: var(--color-success-50);
        --p-button__active-bg-color: var(--color-success-100);
        --p-button__hover-bg-color: var(--p-button__bg-color);
      }

      .p-button-danger {
        --p-button__bg-color: var(--color-alert-50);
        --p-button__active-bg-color: var(--color-alert-100);
        --p-button__hover-bg-color: var(--p-button__bg-color);
      }

      .p-button-warning {
        --p-button__bg-color: var(--color-secondary-50);
        --p-button__active-bg-color: var(--color-secondary-100);
        --p-button__hover-bg-color: var(--p-button__bg-color);
      }
    }

    // Placeholder for outlined state
    .p-button-secondary {
      --p-button__color: var(--color-gray-500);
      --p-button__hover-bg-color: var(--color-gray-100);
      --p-button__active-bg-color: var(--color-gray-100);
      --p-button__active-border-color: var(--color-gray-300);
      --p-button__hover-border-color: var(--color-gray-500);

      --p-button__icon-color: var(--color-gray-500);
      --icon-color: var(--p-button__icon-color);
    }

    .p-button-success {
      --p-button__hover-bg-color: var(--color-success-100);
      --p-button__active-bg-color: var(--color-success-100);
      --p-button__active-border-color: var(--color-success-500);
      --p-button__hover-border-color: var(--color-success-500);
    }

    .p-button-danger {
      --p-button__hover-bg-color: var(--color-alert-100);
      --p-button__active-bg-color: var(--color-alert-100);
      --p-button__active-border-color: var(--color-alert-500);
      --p-button__hover-border-color: var(--color-alert-500);
    }

    .p-button-warning {
      --p-button__hover-bg-color: var(--color-secondary-100);
      --p-button__active-bg-color: var(--color-secondary-100);
      --p-button__active-border-color: var(--color-secondary-500);
      --p-button__hover-border-color: var(--color-secondary-500);
    }
  }

  &:has(.p-button-icon-only) {
    width: var(--p-button__height);

    button {
      width: var(--p-button__height);

      span {
        font-size: var(--p-button__icon-only-font-size);
      }
    }
  }

  &.p-disabled {
    .p-button-secondary {
      // need more because up on this opacity 0.6 from p-button disabled
      --p-button__border-color: var(--color-gray-400);
      --p-button__color: var(--color-gray-500);
      --p-button__icon-color: var(--color-gray-500);
      --icon-color: var(--p-button__icon-color);
    }
  }

  &.no-wrap {
    white-space: nowrap;
  }
}

@include mobile {
  p-button {
    &:has(.p-button-icon-only) {
      width: 40px;
      height: 40px;
      --p-button__height: 40px;

      button {
        width: 40px;
        height: 40px;

        &.p-button {
          border-radius: 6px;
        }
      }
    }
  }

  .fw-btn--mobile {
    width: 100%;

    .p-button {
      width: 100%;
    }
  }
}
