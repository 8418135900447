@import '../breakpoints';
@import '../_shared';

p-menu {
  .p-menu {
    width: 255px;
    padding: 12px;
    border-radius: 12px;
    border: 1px solid var(--color-gray-200);
    margin-top: 120px;
    --p-anchor-gutter: 8px;
  }

  .p-menuitem.error {
    .p-menuitem-icon,
    .p-menuitem-text {
      color: var(--color-alert-500);
    }
  }

  .p-menuitem.item-selected {
    .p-element {
      background-color: var(--color-primary-50);

      .p-menuitem-text, .p-menuitem-icon {
        color: var(--color-primary-500) !important;
      }
    }
  }

  .p-menuitem-text {
    color: var(--color-gray-500);
  }

  .p-menuitem-link {
    @include switzerFont();
  }

  .p-menuitem-content:hover,
  .p-menuitem-content .selected {
    background-color: var(--color-primary-50);
    border-radius: 8px;
  }

  // TODO: Move to the app-dropdown-select component
  .menu-item {
    justify-content: space-between;
    margin-bottom: 4px;
    padding: 12px 16px;
  }

  .selected {
    color: var(--color-primary-500);
  }

  app-icon-check {
    width: 20px;
    height: 20px;
    --menu-icon-color: var(--color-primary-500);
  }

  &.menu-primary {
    .p-menu-overlay {
      border-radius: 6px;
      padding: 0;
    }

    .p-menuitem-link {
      border-top: 1px solid var(--color-gray-200);
      padding: 14px 24px;
    }

    .p-menuitem-icon {
      font-size: 18px;
      margin-right: 8px;
    }

    .p-menuitem-text {
      @include switzerFont();
      font-size: 14px;
      color: var(--color-gray-500);
    }

    .color-custom {
      --icon-color: var(--color-gray-500);
      --text-color: var(--color-gray-500);

      .p-menuitem-icon {
        color: var(--icon-color);
      }

      .p-menuitem-text {
        color: var(--text-color);
      }
    }
  }
}

//Need to remade this solution.
.p-menu {
  .p-menuitem.error {
    .p-menuitem-icon,
    .p-menuitem-text {
      color: var(--color-alert-500);
    }
  }

  &.menu-primary {
    &.br-6 {
      border-radius: 6px;
    }

    &.p-4 {
      padding: 4px 0;
    }

    &.p-8 {
      padding: 8px 0;
    }

    &.mw-170 {
      width: unset;
      min-width: 170px;
    }
  }


  &.grid-actions-menu {
    width: auto;
  }

  &:has(.export-menu-item) {
    width: 300px;
  }

  &:has(.add-device-menu-item) {
    width: auto;
  }

  &:has(.alert-manage-menu-item),
  &:has(.commands-menu-item) {
    @include scrollbarGray;
    width: 320px;
    max-height: 400px;
    overflow-y: auto;

    --p-anchor-gutter: 16px;
  }

  &:has(.tiles-selector-menu-item) {
    .p-menu-list {
      padding: 8px;
    }

    .p-menuitem {
      &:last-child {
        .p-menuitem-link {
          margin-bottom: 0;
        }
      }
    }

    .p-menuitem-link {
      padding: 10px 14px;
      margin-bottom: 4px;
    }

    .p-menuitem-content {
      border-radius: 8px;
    }

    .p-menuitem-content .selected {
      .label {
        @include switzerFont;
        font-size: 14px;
        color: var(--color-primary-500);
      }
    }

    .p-menuitem-content:hover,
    .p-menuitem-content .selected {
      background-color: var(--color-primary-50);
      border-radius: 8px;
    }

    border-radius: 8px;
    border: 1px solid var(--color-gray-200);
    padding: 0;
    --p-anchor-gutter: 12px;
  }

  &:has(.media-actions-menu-item) {
    .p-menuitem-content:hover,
    .p-menuitem-content .selected {
      background-color: var(--color-primary-50);
    }

    .p-menuitem-link {
      padding: 10px 18px;
      border-top: unset;
    }

    .p-menu-overlay {
      width: unset;
      min-width: 170px;
      padding: 8px 0;
    }

    .p-menuitem-content {
      border-radius: 0;
      width: 100%;
    }

    .p-menuitem-icon {
      font-size: 16px;
    }

    .p-menuitem-text {
      font-size: 14px;
    }

    .p-menuitem {
      display: flex;
      align-items: center;
      --text-color: var(--color-gray-500);
      --icon-color: var(--color-gray-500);

      &.alert {
        .p-menuitem-icon {
          color: var(--color-alert-500);
          --icon-color: var(--color-alert-500);
        }

        .p-menuitem-text {
          color: var(--color-alert-500);
        }
      }

      .icon-wrapper {
        width: 16px;
        height: 16px;
        position: relative;
        margin-right: 8px;

        .p-menuitem-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  &:has(.context-actions-menu-item) {
    .p-menuitem-content:hover,
    .p-menuitem-content .selected {
      background-color: var(--color-primary-50);
    }

    .p-menuitem-link {
      padding: 10px 18px;
      border-top: unset;
    }

    .p-menu-overlay {
      width: unset;
      min-width: 170px;
      padding: 8px 0;
    }

    .p-menuitem-content {
      border-radius: 0;
      width: 100%;
    }

    .p-menuitem-icon {
      font-size: 16px;
    }

    .p-menuitem-text {
      font-size: 14px;
    }

    .p-menuitem {
      display: flex;
      align-items: center;
      --text-color: var(--color-gray-500);
      --icon-color: var(--color-gray-500);

      &.alert {
        .p-menuitem-icon {
          color: var(--color-alert-500);
          --icon-color: var(--color-alert-500);
        }

        .p-menuitem-text {
          color: var(--color-alert-500);
        }
      }

      .icon-wrapper {
        width: 16px;
        height: 16px;
        position: relative;
        margin-right: 8px;

        .p-menuitem-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
