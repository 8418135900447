.maplibregl-map {
    font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
    overflow: hidden;
    position: relative;
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

.maplibregl-canvas {
    position: absolute;
    left: 0;
    top: 0;
}

.maplibregl-map:fullscreen {
    width: 100%;
    height: 100%;
}

.maplibregl-ctrl-group button.maplibregl-ctrl-compass {
    touch-action: none;
}

.maplibregl-canvas-container.maplibregl-interactive,
.maplibregl-ctrl-group button.maplibregl-ctrl-compass {
    cursor: grab;
    user-select: none;
}

.maplibregl-canvas-container.maplibregl-interactive.maplibregl-track-pointer {
    cursor: pointer;
}

.maplibregl-canvas-container.maplibregl-interactive:active,
.maplibregl-ctrl-group button.maplibregl-ctrl-compass:active {
    cursor: grabbing;
}

.maplibregl-canvas-container.maplibregl-touch-zoom-rotate,
.maplibregl-canvas-container.maplibregl-touch-zoom-rotate .maplibregl-canvas {
    touch-action: pan-x pan-y;
}

.maplibregl-canvas-container.maplibregl-touch-drag-pan,
.maplibregl-canvas-container.maplibregl-touch-drag-pan .maplibregl-canvas {
    touch-action: pinch-zoom;
}

.maplibregl-canvas-container.maplibregl-touch-zoom-rotate.maplibregl-touch-drag-pan,
.maplibregl-canvas-container.maplibregl-touch-zoom-rotate.maplibregl-touch-drag-pan .maplibregl-canvas {
    touch-action: none;
}

.maplibregl-canvas-container.maplibregl-touch-drag-pan.maplibregl-cooperative-gestures,
.maplibregl-canvas-container.maplibregl-touch-drag-pan.maplibregl-cooperative-gestures .maplibregl-canvas {
    touch-action: pan-x pan-y;
}

.maplibregl-ctrl-top-left,
.maplibregl-ctrl-top-right,
.maplibregl-ctrl-bottom-left,
.maplibregl-ctrl-bottom-right {
    position: absolute;
    pointer-events: none;
    z-index: 2;
}

.maplibregl-ctrl-top-left {
    top: 0;
    left: 0;
}

.maplibregl-ctrl-top-right {
     top: 0;
     right: 0;
    }

.maplibregl-ctrl-bottom-left {
    bottom: 0;
    left: 0;
}

.maplibregl-ctrl-bottom-right {
     right: 0;
     bottom: 0;
}

.maplibregl-ctrl {
    clear: both;
    pointer-events: auto;

    /* workaround for a Safari bug https://github.com/mapbox/mapbox-gl-js/issues/8185 */
    transform: translate(0, 0);
}

.maplibregl-ctrl-top-left .maplibregl-ctrl {
    margin: 10px 0 0 10px;
     float: left;
}

.maplibregl-ctrl-top-right .maplibregl-ctrl {
    margin: 10px 10px 0 0;
    float: right;
}

.maplibregl-ctrl-bottom-left .maplibregl-ctrl {
    margin: 0 0 10px 10px;
    float: left;
}

.maplibregl-ctrl-bottom-right .maplibregl-ctrl {
    margin: 0 10px 10px 0;
    float: right;
}

.maplibregl-ctrl-group {
    border-radius: 4px;
    background: #fff;
}

.maplibregl-ctrl-group:not(:empty) {
    box-shadow: 0 0 0 2px rgb(0 0 0 / 10%);
}

@media (forced-colors: active) {
    .maplibregl-ctrl-group:not(:empty) {
        box-shadow: 0 0 0 2px ButtonText;
    }
}

.maplibregl-ctrl-group button {
    width: 29px;
    height: 29px;
    display: block;
    padding: 0;
    outline: none;
    border: 0;
    box-sizing: border-box;
    background-color: transparent;
    cursor: pointer;
}

.maplibregl-ctrl-group button + button {
    border-top: 1px solid #ddd;
}

.maplibregl-ctrl button .maplibregl-ctrl-icon {
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
}

@media (forced-colors: active) {
    .maplibregl-ctrl-icon {
        background-color: transparent;
    }

    .maplibregl-ctrl-group button + button {
        border-top: 1px solid ButtonText;
    }
}

/* https://bugzilla.mozilla.org/show_bug.cgi?id=140562 */
.maplibregl-ctrl button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.maplibregl-ctrl-attrib-button:focus,
.maplibregl-ctrl-group button:focus {
    box-shadow: 0 0 2px 2px rgb(0 150 255 / 100%);
}

.maplibregl-ctrl button:disabled {
    cursor: not-allowed;
}

.maplibregl-ctrl button:disabled .maplibregl-ctrl-icon {
    opacity: 0.25;
}

.maplibregl-ctrl button:not(:disabled):hover {
    background-color: rgb(0 0 0 / 5%);
}

.maplibregl-ctrl-group button:focus:focus-visible {
    box-shadow: 0 0 2px 2px rgb(0 150 255 / 100%);
}

.maplibregl-ctrl-group button:focus:not(:focus-visible) {
    box-shadow: none;
}

.maplibregl-ctrl-group button:focus:first-child {
    border-radius: 4px 4px 0 0;
}

.maplibregl-ctrl-group button:focus:last-child {
    border-radius: 0 0 4px 4px;
}

.maplibregl-ctrl-group button:focus:only-child {
    border-radius: inherit;
}

.maplibregl-ctrl button.maplibregl-ctrl-zoom-out .maplibregl-ctrl-icon {
    background-image: svg-load("svg/maplibregl-ctrl-zoom-out.svg", fill: #333);
}

.maplibregl-ctrl button.maplibregl-ctrl-zoom-in .maplibregl-ctrl-icon {
    background-image: svg-load("svg/maplibregl-ctrl-zoom-in.svg", fill: #333);
}

@media (forced-colors: active) {
    .maplibregl-ctrl button.maplibregl-ctrl-zoom-out .maplibregl-ctrl-icon {
        background-image: svg-load("svg/maplibregl-ctrl-zoom-out.svg", fill: #fff);
    }

    .maplibregl-ctrl button.maplibregl-ctrl-zoom-in .maplibregl-ctrl-icon {
        background-image: svg-load("svg/maplibregl-ctrl-zoom-in.svg", fill: #fff);
    }
}

@media (forced-colors: active) and (prefers-color-scheme: light) {
    .maplibregl-ctrl button.maplibregl-ctrl-zoom-out .maplibregl-ctrl-icon {
        background-image: svg-load("svg/maplibregl-ctrl-zoom-out.svg", fill: #000);
    }

    .maplibregl-ctrl button.maplibregl-ctrl-zoom-in .maplibregl-ctrl-icon {
        background-image: svg-load("svg/maplibregl-ctrl-zoom-in.svg", fill: #000);
    }
}

.maplibregl-ctrl button.maplibregl-ctrl-fullscreen .maplibregl-ctrl-icon {
    background-image: svg-load("svg/maplibregl-ctrl-fullscreen.svg", fill: #333);
}

.maplibregl-ctrl button.maplibregl-ctrl-shrink .maplibregl-ctrl-icon {
    background-image: svg-load("svg/maplibregl-ctrl-shrink.svg");
}

@media (forced-colors: active) {
    .maplibregl-ctrl button.maplibregl-ctrl-fullscreen .maplibregl-ctrl-icon {
        background-image: svg-load("svg/maplibregl-ctrl-fullscreen.svg", fill: #fff);
    }

    .maplibregl-ctrl button.maplibregl-ctrl-shrink .maplibregl-ctrl-icon {
        background-image: svg-load("svg/maplibregl-ctrl-shrink.svg", fill: #fff);
    }
}

@media (forced-colors: active) and (prefers-color-scheme: light) {
    .maplibregl-ctrl button.maplibregl-ctrl-fullscreen .maplibregl-ctrl-icon {
        background-image: svg-load("svg/maplibregl-ctrl-fullscreen.svg", fill: #000);
    }

    .maplibregl-ctrl button.maplibregl-ctrl-shrink .maplibregl-ctrl-icon {
        background-image: svg-load("svg/maplibregl-ctrl-shrink.svg", fill: #000);
    }
}

.maplibregl-ctrl button.maplibregl-ctrl-compass .maplibregl-ctrl-icon {
    background-image: svg-load("svg/maplibregl-ctrl-compass.svg", fill: #333);
}

@media (forced-colors: active) {
    .maplibregl-ctrl button.maplibregl-ctrl-compass .maplibregl-ctrl-icon {
        @svg-load ctrl-compass-white url("svg/maplibregl-ctrl-compass.svg") {
            fill: #fff;
            #south { fill: #999; }
        }

        background-image: svg-inline(ctrl-compass-white);
    }
}

@media (forced-colors: active) and (prefers-color-scheme: light) {
    .maplibregl-ctrl button.maplibregl-ctrl-compass .maplibregl-ctrl-icon {
        background-image: svg-load("svg/maplibregl-ctrl-compass.svg", fill: #000);
    }
}

@svg-load ctrl-terrain url("svg/maplibregl-ctrl-terrain.svg") {
    fill: #333;
    #stroke { display: none; }
}

@svg-load ctrl-terrain-enabled url("svg/maplibregl-ctrl-terrain.svg") {
    fill: #33b5e5;
    #stroke { display: none; }
}

.maplibregl-ctrl button.maplibregl-ctrl-terrain .maplibregl-ctrl-icon {
    background-image: svg-inline(ctrl-terrain);
}

.maplibregl-ctrl button.maplibregl-ctrl-terrain-enabled .maplibregl-ctrl-icon {
    background-image: svg-inline(ctrl-terrain-enabled);
}

@svg-load ctrl-geolocate url("svg/maplibregl-ctrl-geolocate.svg") {
    fill: #333;
    #stroke { display: none; }
}

@svg-load ctrl-geolocate-white url("svg/maplibregl-ctrl-geolocate.svg") {
    fill: #fff;
    #stroke { display: none; }
}

@svg-load ctrl-geolocate-black url("svg/maplibregl-ctrl-geolocate.svg") {
    fill: #000;
    #stroke { display: none; }
}

@svg-load ctrl-geolocate-disabled url("svg/maplibregl-ctrl-geolocate.svg") {
    fill: #aaa;
    #stroke { fill: #f00; }
}

@svg-load ctrl-geolocate-disabled-white url("svg/maplibregl-ctrl-geolocate.svg") {
    fill: #999;
    #stroke { fill: #f00; }
}

@svg-load ctrl-geolocate-disabled-black url("svg/maplibregl-ctrl-geolocate.svg") {
    fill: #666;
    #stroke { fill: #f00; }
}

@svg-load ctrl-geolocate-active url("svg/maplibregl-ctrl-geolocate.svg") {
    fill: #33b5e5;
    #stroke { display: none; }
}

@svg-load ctrl-geolocate-active-error url("svg/maplibregl-ctrl-geolocate.svg") {
    fill: #e58978;
    #stroke { display: none; }
}

@svg-load ctrl-geolocate-background url("svg/maplibregl-ctrl-geolocate.svg") {
    fill: #33b5e5;
    #stroke { display: none; }
    #dot { display: none; }
}

@svg-load ctrl-geolocate-background-error url("svg/maplibregl-ctrl-geolocate.svg") {
    fill: #e54e33;
    #stroke { display: none; }
    #dot { display: none; }
}

.maplibregl-ctrl button.maplibregl-ctrl-geolocate .maplibregl-ctrl-icon {
    background-image: svg-inline(ctrl-geolocate);
}

.maplibregl-ctrl button.maplibregl-ctrl-geolocate:disabled .maplibregl-ctrl-icon {
    background-image: svg-inline(ctrl-geolocate-disabled);
}

.maplibregl-ctrl button.maplibregl-ctrl-geolocate.maplibregl-ctrl-geolocate-active .maplibregl-ctrl-icon {
    background-image: svg-inline(ctrl-geolocate-active);
}

.maplibregl-ctrl button.maplibregl-ctrl-geolocate.maplibregl-ctrl-geolocate-active-error .maplibregl-ctrl-icon {
    background-image: svg-inline(ctrl-geolocate-active-error);
}

.maplibregl-ctrl button.maplibregl-ctrl-geolocate.maplibregl-ctrl-geolocate-background .maplibregl-ctrl-icon {
    background-image: svg-inline(ctrl-geolocate-background);
}

.maplibregl-ctrl button.maplibregl-ctrl-geolocate.maplibregl-ctrl-geolocate-background-error .maplibregl-ctrl-icon {
    background-image: svg-inline(ctrl-geolocate-background-error);
}

.maplibregl-ctrl button.maplibregl-ctrl-geolocate.maplibregl-ctrl-geolocate-waiting .maplibregl-ctrl-icon {
    animation: maplibregl-spin 2s infinite linear;
}

@media (forced-colors: active) {
    .maplibregl-ctrl button.maplibregl-ctrl-geolocate .maplibregl-ctrl-icon {
        background-image: svg-inline(ctrl-geolocate-white);
    }

    .maplibregl-ctrl button.maplibregl-ctrl-geolocate:disabled .maplibregl-ctrl-icon {
        background-image: svg-inline(ctrl-geolocate-disabled-white);
    }

    .maplibregl-ctrl button.maplibregl-ctrl-geolocate.maplibregl-ctrl-geolocate-active .maplibregl-ctrl-icon {
        background-image: svg-inline(ctrl-geolocate-active);
    }

    .maplibregl-ctrl button.maplibregl-ctrl-geolocate.maplibregl-ctrl-geolocate-active-error .maplibregl-ctrl-icon {
        background-image: svg-inline(ctrl-geolocate-active-error);
    }

    .maplibregl-ctrl button.maplibregl-ctrl-geolocate.maplibregl-ctrl-geolocate-background .maplibregl-ctrl-icon {
        background-image: svg-inline(ctrl-geolocate-background);
    }

    .maplibregl-ctrl button.maplibregl-ctrl-geolocate.maplibregl-ctrl-geolocate-background-error .maplibregl-ctrl-icon {
        background-image: svg-inline(ctrl-geolocate-background-error);
    }
}

@media (forced-colors: active) and (prefers-color-scheme: light) {
    .maplibregl-ctrl button.maplibregl-ctrl-geolocate .maplibregl-ctrl-icon {
        background-image: svg-inline(ctrl-geolocate-black);
    }

    .maplibregl-ctrl button.maplibregl-ctrl-geolocate:disabled .maplibregl-ctrl-icon {
        background-image: svg-inline(ctrl-geolocate-disabled-black);
    }
}

@keyframes maplibregl-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

a.maplibregl-ctrl-logo {
    width: 88px;
    height: 23px;
    margin: 0 0 -4px -4px;
    display: block;
    background-repeat: no-repeat;
    cursor: pointer;
    overflow: hidden;
    background-image: svg-load("svg/maplibregl-ctrl-logo.svg");
}

a.maplibregl-ctrl-logo.maplibregl-compact {
    width: 14px;
}

@media (forced-colors: active) {
    a.maplibregl-ctrl-logo {
        @svg-load ctrl-logo-white url("svg/maplibregl-ctrl-logo.svg") {
            #outline { opacity: 1; }
            #fill { opacity: 1; }
        }

        background-color: transparent;
        background-image: svg-inline(ctrl-logo-white);
    }
}

@media (forced-colors: active) and (prefers-color-scheme: light) {
    a.maplibregl-ctrl-logo {
        @svg-load ctrl-logo-black url("svg/maplibregl-ctrl-logo.svg") {
            #outline { opacity: 1; fill: #fff; stroke: #fff; }
            #fill { opacity: 1; fill: #000; }
        }

        background-image: svg-inline(ctrl-logo-black);
    }
}

.maplibregl-ctrl.maplibregl-ctrl-attrib {
    padding: 0 5px;
    background-color: rgb(255 255 255 / 50%);
    margin: 0;
}

@media screen {
    .maplibregl-ctrl-attrib.maplibregl-compact {
        min-height: 20px;
        padding: 2px 24px 2px 0;
        margin: 10px;
        position: relative;
        background-color: #fff;
        color: #000;
        border-radius: 12px;
        box-sizing: content-box;
    }

    .maplibregl-ctrl-attrib.maplibregl-compact-show {
        padding: 2px 28px 2px 8px;
        visibility: visible;
    }

    .maplibregl-ctrl-top-left > .maplibregl-ctrl-attrib.maplibregl-compact-show,
    .maplibregl-ctrl-bottom-left > .maplibregl-ctrl-attrib.maplibregl-compact-show {
        padding: 2px 8px 2px 28px;
        border-radius: 12px;
    }

    .maplibregl-ctrl-attrib.maplibregl-compact .maplibregl-ctrl-attrib-inner {
        display: none;
    }

    .maplibregl-ctrl-attrib-button {
        display: none;
        cursor: pointer;
        position: absolute;
        background-image: svg-load("svg/maplibregl-ctrl-attrib.svg");
        background-color: rgb(255 255 255 / 50%);
        width: 24px;
        height: 24px;
        box-sizing: border-box;
        border-radius: 12px;
        outline: none;
        top: 0;
        right: 0;
        border: 0;
    }

    .maplibregl-ctrl-attrib summary.maplibregl-ctrl-attrib-button {
        appearance: none;
        list-style: none;
    }

    .maplibregl-ctrl-attrib summary.maplibregl-ctrl-attrib-button::-webkit-details-marker {
        display: none;
    }

    .maplibregl-ctrl-top-left .maplibregl-ctrl-attrib-button,
    .maplibregl-ctrl-bottom-left .maplibregl-ctrl-attrib-button {
        left: 0;
    }

    .maplibregl-ctrl-attrib.maplibregl-compact .maplibregl-ctrl-attrib-button,
    .maplibregl-ctrl-attrib.maplibregl-compact-show .maplibregl-ctrl-attrib-inner {
        display: block;
    }

    .maplibregl-ctrl-attrib.maplibregl-compact-show .maplibregl-ctrl-attrib-button {
        background-color: rgb(0 0 0 / 5%);
    }

    .maplibregl-ctrl-bottom-right > .maplibregl-ctrl-attrib.maplibregl-compact::after {
        bottom: 0;
        right: 0;
    }

    .maplibregl-ctrl-top-right > .maplibregl-ctrl-attrib.maplibregl-compact::after {
        top: 0;
        right: 0;
    }

    .maplibregl-ctrl-top-left > .maplibregl-ctrl-attrib.maplibregl-compact::after {
        top: 0;
        left: 0;
    }

    .maplibregl-ctrl-bottom-left > .maplibregl-ctrl-attrib.maplibregl-compact::after {
        bottom: 0;
        left: 0;
    }
}

@media screen and (forced-colors: active) {
    .maplibregl-ctrl-attrib.maplibregl-compact::after {
        background-image: svg-load("svg/maplibregl-ctrl-attrib.svg", fill=#fff);
    }
}

@media screen and (forced-colors: active) and (prefers-color-scheme: light) {
    .maplibregl-ctrl-attrib.maplibregl-compact::after {
        background-image: svg-load("svg/maplibregl-ctrl-attrib.svg");
    }
}

.maplibregl-ctrl-attrib a {
    color: rgb(0 0 0 / 75%);
    text-decoration: none;
}

.maplibregl-ctrl-attrib a:hover {
    color: inherit;
    text-decoration: underline;
}

.maplibregl-attrib-empty {
    display: none;
}

.maplibregl-ctrl-scale {
    background-color: rgb(255 255 255 / 75%);
    font-size: 10px;
    border-width: medium 2px 2px;
    border-style: none solid solid;
    border-color: #333;
    padding: 0 5px;
    color: #333;
    box-sizing: border-box;
}

.maplibregl-popup {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    will-change: transform;
    pointer-events: none;
}

.maplibregl-popup-anchor-top,
.maplibregl-popup-anchor-top-left,
.maplibregl-popup-anchor-top-right {
    flex-direction: column;
}

.maplibregl-popup-anchor-bottom,
.maplibregl-popup-anchor-bottom-left,
.maplibregl-popup-anchor-bottom-right {
    flex-direction: column-reverse;
}

.maplibregl-popup-anchor-left {
    flex-direction: row;
}

.maplibregl-popup-anchor-right {
    flex-direction: row-reverse;
}

.maplibregl-popup-tip {
    width: 0;
    height: 0;
    border: 10px solid transparent;
    z-index: 1;
}

.maplibregl-popup-anchor-top .maplibregl-popup-tip {
    align-self: center;
    border-top: none;
    border-bottom-color: #fff;
}

.maplibregl-popup-anchor-top-left .maplibregl-popup-tip {
    align-self: flex-start;
    border-top: none;
    border-left: none;
    border-bottom-color: #fff;
}

.maplibregl-popup-anchor-top-right .maplibregl-popup-tip {
    align-self: flex-end;
    border-top: none;
    border-right: none;
    border-bottom-color: #fff;
}

.maplibregl-popup-anchor-bottom .maplibregl-popup-tip {
    align-self: center;
    border-bottom: none;
    border-top-color: #fff;
}

.maplibregl-popup-anchor-bottom-left .maplibregl-popup-tip {
    align-self: flex-start;
    border-bottom: none;
    border-left: none;
    border-top-color: #fff;
}

.maplibregl-popup-anchor-bottom-right .maplibregl-popup-tip {
    align-self: flex-end;
    border-bottom: none;
    border-right: none;
    border-top-color: #fff;
}

.maplibregl-popup-anchor-left .maplibregl-popup-tip {
    align-self: center;
    border-left: none;
    border-right-color: #fff;
}

.maplibregl-popup-anchor-right .maplibregl-popup-tip {
    align-self: center;
    border-right: none;
    border-left-color: #fff;
}

.maplibregl-popup-close-button {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    border-radius: 0 3px 0 0;
    cursor: pointer;
    background-color: transparent;
}

.maplibregl-popup-close-button:hover {
    background-color: rgb(0 0 0 / 5%);
}

.maplibregl-popup-content {
    position: relative;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
    padding: 15px 10px;
    pointer-events: auto;
}

.maplibregl-popup-anchor-top-left .maplibregl-popup-content {
    border-top-left-radius: 0;
}

.maplibregl-popup-anchor-top-right .maplibregl-popup-content {
    border-top-right-radius: 0;
}

.maplibregl-popup-anchor-bottom-left .maplibregl-popup-content {
    border-bottom-left-radius: 0;
}

.maplibregl-popup-anchor-bottom-right .maplibregl-popup-content {
    border-bottom-right-radius: 0;
}

.maplibregl-popup-track-pointer {
    display: none;
}

.maplibregl-popup-track-pointer * {
    pointer-events: none;
    user-select: none;
}

.maplibregl-map:hover .maplibregl-popup-track-pointer {
    display: flex;
}

.maplibregl-map:active .maplibregl-popup-track-pointer {
    display: none;
}

.maplibregl-marker {
    position: absolute;
    top: 0;
    left: 0;
    will-change: transform;
    transition: opacity 0.2s;
}

.maplibregl-user-location-dot {
    background-color: #1da1f2;
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.maplibregl-user-location-dot::before {
    background-color: #1da1f2;
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    animation: maplibregl-user-location-dot-pulse 2s infinite;
}

.maplibregl-user-location-dot::after {
    border-radius: 50%;
    border: 2px solid #fff;
    content: "";
    height: 19px;
    left: -2px;
    position: absolute;
    top: -2px;
    width: 19px;
    box-sizing: border-box;
    box-shadow: 0 0 3px rgb(0 0 0 / 35%);
}

@keyframes maplibregl-user-location-dot-pulse {
    0%   { transform: scale(1); opacity: 1; }
    70%  { transform: scale(3); opacity: 0; }
    100% { transform: scale(1); opacity: 0; }
}

.maplibregl-user-location-dot-stale {
    background-color: #aaa;
}

.maplibregl-user-location-dot-stale::after {
    display: none;
}

.maplibregl-user-location-accuracy-circle {
    background-color: #1da1f233;
    width: 1px;
    height: 1px;
    border-radius: 100%;
}

.maplibregl-crosshair,
.maplibregl-crosshair .maplibregl-interactive,
.maplibregl-crosshair .maplibregl-interactive:active {
    cursor: crosshair;
}

.maplibregl-boxzoom {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background: #fff;
    border: 2px dotted #202020;
    opacity: 0.5;
}

.maplibregl-cooperative-gesture-screen {
    background: rgba(0 0 0 / 40%);
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 1rem;
    font-size: 1.4em;
    line-height: 1.2;
    opacity: 0;
    pointer-events: none;
    transition: opacity 1s ease 1s;
    z-index: 99999;
}

.maplibregl-cooperative-gesture-screen.maplibregl-show {
    opacity: 1;
    transition: opacity 0.05s;
}

.maplibregl-cooperative-gesture-screen .maplibregl-mobile-message {
    display: none;
}

@media (hover: none), (width <= 480px) {
    .maplibregl-cooperative-gesture-screen .maplibregl-desktop-message {
        display: none;
    }

    .maplibregl-cooperative-gesture-screen .maplibregl-mobile-message {
        display: block;
    }
}

.maplibregl-pseudo-fullscreen {
    position: fixed !important;
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 99999;
}
