@import '../breakpoints';
@import '../_shared';

p-panel {
  &.panel-primary {
    --border-radius: 16px;
    width: 420px;

    .p-panel-header {
      background-color: var(--color-gray-0);
      border-top-right-radius: var(--border-radius);
      border-top-left-radius: var(--border-radius);
      border-bottom: unset;
      padding: 32px 32px 24px 32px;
    }

    .p-panel-content {
      border-bottom-right-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
      padding: 0 32px 32px 32px;
    }

    &.header-16 {
      .p-panel-header {
        padding: 16px;
      }
    }

    &.header-24 {
      .p-panel-header {
        padding: 24px;
      }
    }

    &.header-32 {
      .p-panel-header {
        padding: 32px;
      }
    }

    &.with-footer {
      .p-panel-content {
        border-radius: 0;
      }

      .p-panel-footer {
        border-bottom-right-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
      }

      &.footer-24 {
        .p-panel-footer {
          padding: 24px;
        }
      }
    }
  }

  &.w-285 {
    width: 285px;
  }

  &.w-380 {
    width: 380px;
  }

  &.w-420 {
    width: 420px;
  }

  &.w-430 {
    width: 430px;
  }

  &.w-608 {
    width: 608px;
  }

  .caption {
    color: var(--color-gray-900);
  }

  .title {
    color: var(--color-gray-900);
  }

  .subtitle {
    color: var(--color-gray-500);
  }

  &.content-16 {
    .p-panel-content {
      padding: 16px 16px 16px 16px;
    }
  }

  &.content-24 {
    .p-panel-content {
      padding: 24px 24px 24px 24px;
    }
  }

  &.content-32 {
    .p-panel-content {
      padding: 32px 32px 32px 32px;
    }
  }

  &.content-32,
  &.content-24,
  &.content-16 {
    &.border-top {
      .p-panel-content {
        border-top: 1px solid var(--color-gray-200);
      }
    }
    &.border-bottom {
      .p-panel-content {
        border-bottom: 1px solid var(--color-gray-200);
      }
    }
  }
}

@include tabletSmall {
  p-panel {
    &.panel-primary {
      .p-panel-header {
        margin-bottom: -1px;
      }
    }
  }
}

@include mobile {
  p-panel {
    &.panel-primary {
      .p-panel-header {
        padding: 24px 24px 32px 24px;
      }

      .p-panel-content {
        padding: 0 24px 24px 24px;
      }
    }
  }
}
