@import '../breakpoints';
@import '../_shared';

p-autocomplete {
  display: block;

  &.h {
    input {
      padding: 10px;
      font-size: 14px;
      line-height: 20px;
    }

    &--34 {
      height: 34px;
      @extend .h;

      input {
        height: 34px;
      }
    }

    &--38 {
      height: 38px;
      @extend .h;

      input {
        height: 38px;
      }
    }

    &--40 {
      height: 40px;
      @extend .h;

      input {
        height: 40px;
      }
    }
  }

  &.w100 {
    .p-autocomplete,
    .p-component,
    .p-inputwrapper {
      width: 100%;

      input {
        width: 100%;
      }
    }
  }

  .p-autocomplete-panel {
    margin-top: 12px;
    @include scrollbarGray;
  }

  &.with-filter-icon {
    input {
      padding-right: 40px;
      --icon-right-padding: 40px;
    }
  }

  &:has(.p-icon-wrapper) {
    input {
      padding-right: 40px;
    }
  }
}

p-autocompleteitem {
  --background-color: var(--color-primary-50);
  --text-color: var(--color-primary-500);

  .p-autocomplete-item {
    padding: 10px 18px;
    color: var(--color-gray-900);
  }

  .p-highlight {
    background-color: var(--background-color);
    color: var(--text-color);
  }
}

// @TODO Need to investigate this solution deeper
.p-autocomplete-items-wrapper {
  margin-top: 12px;
  border: 1px solid var(--color-gray-200);
  border-radius: 6px;
}

@include mobile {
  p-autoComplete {
    .p-autocomplete,
    .p-component,
    .p-inputwrapper {
      input {
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }
  }
}
