@import '../breakpoints';
@import '../_shared';

p-dropdown {
  display: block;
  --p-dropdown__height: 40px;

  chevrondownicon {
    height: 14px;
    width: 14px;
    line-height: 18px;
  }

  span,
  .p-dropdown-label {
    display: flex;
    align-items: center;
    font-size: 14px;
    @include switzerFont();
  }

  .p-dropdown-label {
    height: var(--p-dropdown__height);

    &.p-inputtext {
    }
    color: var(--color-gray-900);
    font-weight: 500;
  }

  &.panel-400 {
    .p-dropdown-items-wrapper {
      max-height: 400px !important;
    }
  }

  &.h {
    height: var(--p-dropdown__height);
    @extend .h;

    .p-dropdown {
      height: var(--p-dropdown__height);
    }

    span {
      padding: 0 10px;
    }

    .p-dropdown-trigger {
      width: 34px;
    }

    &--34 {
      @extend .h;
      --p-dropdown__height: 34px;
    }

    &--38 {
      @extend .h;
      --p-dropdown__height: 38px;
    }

    &--40 {
      @extend .h;
      --p-dropdown__height: 40px;
    }

    &--48 {
      @extend .h;
      --p-dropdown__height: 48px;
    }
  }

  &.video-duration {
    .p-dropdown-item {
      padding: 0;
    }

    .p-dropdown-label {
      padding: 0 12px;
    }

    .p-dropdown {
      border-color: var(--color-primary-500);
      border-radius: 4px;
    }

    .p-dropdown-item:not(.p-highlight) {
      .time {
        color: var(--color-gray-500);
      }
      .description {
        color: var(--color-gray-400);
      }
    }

    &:has(.selected-item) {
      .p-dropdown-label {
        background-color: var(--color-primary-50);
      }
    }

    .p-dropdown-item.p-highlight {
      .description {
        color: var(--color-primary-400);
      }
    }

    .p-dropdown-trigger {
      display: none;
    }
  }

  &.filter {
    .p-dropdown-header {
      padding: 0;
      background-color: var(--colro-gray-0);
      border: unset;
    }

    .p-dropdown-panel {
      margin-top: 12px;
    }

    &:has(.p-dropdown-header) {
      .p-dropdown-items-wrapper {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }
    }
  }
}

p-dropdownitem {
  --background-color: var(--color-primary-50);
  --text-color: var(--color-gray-900);

  .p-dropdown-item {
    padding: 10px 18px;
    color: var(--color-gray-900);
  }

  .p-highlight {
    --text-color: var(--color-primary-500);

    background-color: var(--background-color);
    color: var(--text-color);
  }
}

// @TODO Need to investigate this solution deeper
.p-dropdown-items-wrapper {
  margin-top: 12px;
  border: 1px solid var(--color-gray-200);
  border-radius: 6px;
  @include scrollbarGray;

  &:has(.vehicle-selector-item) {
    max-height: 400px !important;
  }
}

.p-dropdown-empty-message {
  @extend .text-14;
  @include switzerFont();
  color: var(--color-gray-500);
}

.p-dropdown-panel {
  &:has(.vehicle-selector-item) {
  }

  &:has(.p-dropdown-header) {
    margin-top: 12px;

    .p-dropdown-header {
      padding: 0;
      background-color: var(--colro-gray-0);
      border: unset;
    }

    .p-dropdown-panel {
      margin-top: 12px;
    }

    .p-dropdown-items-wrapper {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }

    .p-inputgroup {
      padding: 16px 16px 2px 16px;
      p-iconField {
        width: 100%;
        span {
          padding: 0px;
        }
      }

      input {
        width: 100%;
      }
    }
  }
}

.p-dropdown-item-group {
  background-color: var(--color-gray-50);
  color: var(--color-gray-400);
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  text-transform: uppercase;
  @include switzerFont;
  padding: 6px 18px;
}
