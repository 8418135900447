@import '../breakpoints';
@import '../_shared';

.p-overlaypanel {
  .p-overlaypanel-content {
    padding: 20px;
  }

  // TODO: Need to remade this solution
  &:has(.status-tags-container) {
    .p-overlaypanel-content {
      padding: 10px;
    }

    margin-top: 12px;

    &::after {
      display: none;
    }

    &::before {
      display: none;
    }
  }

  &.custom-overlay-panel {
    &::after,
    &::before {
      display: none;
    }

    box-shadow: 0 0 60px -4px var(--color-gray-200);

    .p-overlaypanel-content {
      padding: 16px;
      width: 456px;

      .label {
        color: var(---color-gray-900);
      }
    }

    @include mobile {
      .p-overlaypanel-content {
        width: 100vw;
      }
    }

    // TODO: Find better way for it
    &:has(app-date-range-picker) {
      .p-overlaypanel-content {
        padding: 0;
        width: auto;
      }

      border: none;
      border-radius: 8px;
    }

    &.full-width {
      width: 100%;
    }

    &.p-0 {
      .p-overlaypanel-content {
        padding: 0px;
      }
    }

    &.width-unset {
      .p-overlaypanel-content {
        width: unset;
      }
    }

    &.transparent {
      background-color: transparent;
    }

    &.anchor {
      .p-overlaypanel-content {
        padding: 0;
        width: 20px;
        height:0
      }
    }
  }
}


