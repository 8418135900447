@mixin desktop {
  @media all and (max-width: 1920px) {
    @content
  }
}

@mixin desktop-small {
  @media all and (max-width: 1500px) {
    @content
  }
}

@mixin laptop {
  @media all and (max-width: 1280px) {
    @content
  }
}

@mixin tablet {
  @media all and (max-width: 1024px) {
    @content
  }
}

@mixin tabletSmall {
  @media all and (max-width: 768px) {
    @content
  }
}

@mixin mobile {
  @media all and (max-width: 480px) {
    @content
  }
}

@mixin mobileSmall {
  @media all and (max-width: 380px) {
    @content
  }
}

@mixin mobileLandscape {
  @media screen and (max-width: 1280px) and (max-height: 480px) and (orientation: landscape) {
    @content
  }
}
