@import '../breakpoints';
@import '../_shared';

p-table {
  &.no-wrap-cols {
    text-wrap: nowrap;
  }

  .p-component-overlay {
    background-color: rgba(0, 0, 0, 0.005);
  }

  &.table-primary {
    border-color: var(--color-gray-200);

    .p-datatable-wrapper {
      border: 1px solid var(--color-gray-200);
      border-radius: 8px;
      position: relative;
      overflow: auto;
      @include scrollbarGray();
    }

    .p-datatable-loading-overlay {
      border-radius: 8px 8px 0 0;
    }

    p-paginator {
      .p-paginator {
        border-right: 1px solid var(--color-gray-200);
        border-bottom: 1px solid var(--color-gray-200);
        border-left: 1px solid var(--color-gray-200);
        border-radius: 0 0 8px 8px;
      }
    }

    th {
      @include switzerFont();
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
      padding: 14px;
      color: var(--color-gray-500);
      background-color: var(--color-gray-50);

      &:not(&.p-highlight) {
        .p-icon {
          color: var(--color-gray-500);
        }
      }

      p-sorticon {
        .p-icon {
          font-size: 12px;
          height: 12px;
          width: 12px;
        }
      }
    }

    td {
      font-size: 14px;
      line-height: 18px;
      padding: 14px;

      & div {
        line-height: 18px;
      }
    }
  }
}

@include mobile {
  p-table.table-primary {
    .p-datatable-wrapper {
      border-radius: 0;
    }
  }
}
