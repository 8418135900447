body {
  --color-white: #ffffff;
  // Primary
  --color-primary-700: #593c99;
  --color-primary-600: #6645ae;
  --color-primary-500: #7f56d9;
  --color-primary-400: #b692f6;
  --color-primary-300: #d6bbfb;
  --color-primary-200: #e9d7fe;
  --color-primary-100: #f4ebff;
  --color-primary-50: #f9f5ff;
  --color-primary-25: #fcfaff;
  // Secondary
  --color-secondary-500: #fa772e;
  --color-secondary-400: #f5b592;
  --color-secondary-300: #f6cdb6;
  --color-secondary-200: #f7ddcf;
  --color-secondary-100: #f8ece5;
  --color-secondary-50: #fff4ed;
  --color-secondary-25: #fffaf8;
  // Gray
  --color-gray-1000: #000000;
  --color-gray-900: #101828;
  --color-gray-800: #1d2939;
  --color-gray-700: #344054;
  --color-gray-600: #475467;
  --color-gray-500: #667085;
  --color-gray-400: #98a2b3;
  --color-gray-300: #d0d5dd;
  --color-gray-200: #e4e7ec;
  --color-gray-100: #f2f4f7;
  --color-gray-50: #f9fafb;
  --color-gray-25: #fcfcfd;
  --color-gray-0: #ffffff;

  --color-success-500: #12b76a;
  --color-success-100: #d1fadf;
  --color-success-50: #e8fdef;

  --color-alert: #d92d20;
  --color-alert-500: #c3505d;
  --color-alert-300: #eeacb3;
  --color-alert-100: #f6e5e7;
  --color-alert-50: #f8eced;

  --color-error-bg: #ffe7e6;
  --color-error: #ff5757;

  --color-info-500: #0b84fe;
}
