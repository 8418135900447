@import '../breakpoints';
@import '../_shared';

p-radiobutton {
  &.label-margin-9 {
    .p-radiobutton-label {
      margin-left: 9px;
    }
  }

  &.label-margin-12 {
    .p-radiobutton-label {
      margin-left: 12px;
    }
  }

  &.radiobutton-secondary {
    .p-radiobutton-checked {
      .p-radiobutton-box {
        background-color: var(--color-gray-0);
        border: 2px solid var(--color-primary-500);
      }
      .p-radiobutton-icon {
        background-color: var(--color-primary-500);
      }
    }

    .p-radiobutton-label {
      color: inherit;
    }
  }

  &.h {
    &--20 {
      .p-radiobutton {
        width: 20px;
        height: 20px;
      }
      .p-radiobutton-box {
        width: 20px;
        height: 20px;
      }
      .p-radiobutton-icon {
        width: 10px;
        height: 10px;
      }
      @extend .h
    }
  }
}
