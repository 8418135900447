@import '../breakpoints';
@import '../_shared';

.p-tooltip {
  &.p-tooltip-top {
    transform: translateY(-15px);

    .p-tooltip-arrow {
      border-top-color: var(--color-gray-900);
    }
  }

  &.p-tooltip-bottom {
    transform: translateY(15px);

    .p-tooltip-arrow {
      border-bottom-color: var(--color-gray-900);
    }
  }

  &.p-tooltip-left {
    transform: translateX(-10px);

    .p-tooltip-arrow {
      border-left-color: var(--color-gray-900);
    }
  }

  &.p-tooltip-right {
    transform: translateX(10px);

    .p-tooltip-arrow {
      border-right-color: var(--color-gray-900);
    }
  }

  .p-tooltip-text {
    font-weight: 500;
    font-size: 12px;
    padding: 8px 12px;
    background-color: var(--color-gray-900);
  }

  &.mw-240 {
    max-width: 240px;
  }

  &.mw-280 {
    max-width: 280px;
  }
}
