@import '../breakpoints';
@import '../_shared';

.p-multiselect-panel {
  // TODO: Need to rework this selector
  &:has(.recipients-custom-header) {
    .p-multiselect-header {
      .p-checkbox,
      .p-multiselect-filter-container,
      .p-multiselect-clear-icon,
      .p-multiselect-close {
        display: none;
      }
    }
  }

  // checkbox 32px + 32px - left and right paddings
  max-width: calc(100vw - 64px);

  @include mobile {
    // checkbox 16px + 16px - left and right paddings
    max-width: calc(100vw - 32px);
  }

  .p-multiselect-item {
    font-size: 14px;
    padding: 11px 18px;
    @include switzerFont;

    span {
      font-size: 14px;
      display: inline-block;
      // checkbox 22px + 18px checkbox margin
      width: calc(100% - 40px);
      text-overflow: ellipsis;
      overflow: hidden;
      text-wrap: nowrap;
    }
  }

  .p-multiselect-empty-message {
    font-size: 14px;
    @include switzerFont;
  }
}

p-multiselect {
  --token-font-size: 14px;
  --token-line-height: 16px;
  --token-padding: 8px 8px 8px 12px;
  --token-text-color: var(--color-primary-500);
  --token-background-color: var(--color-primary-50);
  --token-border-radius: 6px;
  --placeholder-color: var(--color-gray-500);

  .p-multiselect-panel {
    margin-top: 12px;
  }

  .p-multiselect-token {
    margin-right: 4px;
    font-size: var(--token-font-size);
    line-height: var(--token-line-height);
    padding: var(--token-padding);
    color: var(--token-text-color);
    background-color: var(--token-background-color);
    border-radius: var(--border-radius);
  }

  .p-placeholder {
    color: var(--placeholder-color);
    font-size: 14px;
    @include switzerFont;
  }

  &.clear-all-hidden {
    .p-multiselect-label-container {
      padding-right: 0;

      timesicon {
        display: none;
      }
    }
  }

  &.custom-header {
    .p-multiselect-header {
      .p-checkbox,
      .p-multiselect-filter-container,
      .p-multiselect-clear-icon,
      .p-multiselect-close {
        display: none;
      }
    }
  }

  &.header-18 {
    .p-multiselect-header {
      padding: 18px;
    }
  }

  .p-multiselect-items-wrapper {
    @include scrollbarGray;
  }

  .p-multiselect-header {
    padding: 8px 18px;
    background-color: var(--color-gray-0);
  }

  .p-multiselect-close {
    margin-left: 4px;
    width: 24px;
    height: 24px;
  }

  .p-checkbox {
    margin-right: 18px;
  }

  .p-multiselect-filter {
    padding: 6px 12px;
  }

  &.token-wrap {
    .p-multiselect-token {
      margin: 0;
      max-width: 100%;

      .p-multiselect-token-label {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .p-multiselect-label {
      flex-wrap: wrap;
      display: inline-flex;
      gap: 4px;
    }

    .p-multiselect-token-label {
      overflow: unset;
      text-overflow: unset;
      white-space: unset;
      max-width: unset;
      font-size: 14px;
      @include switzerFont();
      font-weight: 600;
      line-height: 16px;
    }
  }

  .p-multiselect-trigger {
    width: 34px;
  }

  .p-multiselect-label {
    padding: 10px 10px;
  }

  &.h {
    chevrondownicon {
      height: 14px;
      width: 14px;
    }

    .p-multiselect-clear-icon {
      height: 14px;
      width: 14px;
      right: 36px;
    }

    .p-multiselect-label {
      &:has(> .p-multiselect-token) {
        padding: 3px 3px 3px 3px;
      }
    }

    .p-multiselect-token-icon {
      margin-left: 0;
    }

    timescircleicon {
      position: relative;
      margin-left: 6px;

      &::after {
        content: '';
        display: block;
        cursor: pointer;
        height: 17px;
        width: 17px;
        background-color: transparent;
        border: 4px solid var(--token-background-color);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &--34 {
      height: 34px;
      @extend .h;

      .p-multiselect-label {
        padding: 6px 10px;
      }

      .p-multiselect-token {
        --token-padding: 5px 10px;
      }
    }

    &--38 {
      height: 38px;
      @extend .h;

      .p-multiselect-label {
        padding: 8px 10px;
      }

      .p-multiselect-token {
        --token-padding: 7px 10px;
        border-radius: 4px;
      }
    }

    &--40 {
      height: 40px;
      @extend .h;

      .p-multiselect-label {
        padding: 9px 10px;
      }
    }
  }
}
