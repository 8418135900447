@import url('https://fonts.cdnfonts.com/css/switzer');

body {
  --primary-font: 'Switzer';
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin switzerFont {
  font-family: 'Switzer', sans-serif;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 700;
}

.fw-900 {
  font-weight: 700;
}

.text-10 {
  font-family: var(--primary-font), sans-serif;
  font-size: 10px;
  line-height: 10px;
}

.text-11 {
  font-family: var(--primary-font), sans-serif;
  font-size: 11px;
  line-height: 16.5px;
}

.text-12 {
  font-family: var(--primary-font), sans-serif;
  font-size: 12px;
  line-height: 16px;
}

.text-13 {
  font-family: var(--primary-font), sans-serif;
  font-size: 13px;
  line-height: 20px;
}

.text-14 {
  font-family: var(--primary-font), sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.text-16 {
  font-family: var(--primary-font), sans-serif;
  font-size: 16px;
  line-height: 24px;
}

.text-18 {
  font-family: var(--primary-font), sans-serif;
  font-size: 18px;
  line-height: 24px;
}

.text-20 {
  font-family: var(--primary-font), sans-serif;
  font-size: 20px;
  line-height: 28px;
}

.text-24 {
  font-family: var(--primary-font), sans-serif;
  font-size: 24px;
  line-height: 32px;
}

.text-28 {
  font-family: var(--primary-font), sans-serif;
  font-size: 28px;
  line-height: 36px;
}

.text-32 {
  font-family: var(--primary-font), sans-serif;
  font-size: 32px;
  line-height: 40px;
}

.text-35 {
  font-family: var(--primary-font), sans-serif;
  font-size: 35px;
  line-height: 35px;
}

.text-light-description--panel {
  @extend .text-14;
  @extend .fw-400;
  color: var(--color-gray-500);
}

.text-color-grey-900 {
  color: var(--color-gray-900);
}
