@import '../breakpoints';
@import '../_shared';

p-inputMask {
  display: block;
  --p-inputMask__height: 40px;

  input {
    display: block;
    padding: 10px;
    font-size: 14px;
    line-height: 20px;
  }

  &.h {
    height: var(--p-inputMask__height);
    @extend .h;

    input {
      height: var(--p-inputMask__height);
    }

    &--34 {
      @extend .h;
      --p-inputMask__height: 34px;
    }

    &--38 {
      @extend .h;
      --p-inputMask__height: 38px;
    }

    &--40 {
      @extend .h;
      --p-inputMask__height: 40px;
    }

    &--48 {
      @extend .h;
      font-size: 16px;
      line-height: 24px;
      --p-inputMask__height: 48px;
    }
  }
}
