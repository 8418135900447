@import '../breakpoints';
@import '../_shared';

p-checkbox {
  .p-checkbox-box {
    &:not(:hover):not(:focus):not(:active) {
      border: 1px solid var(--color-gray-300);
    }
  }

  &.label-margin-9 {
    .p-checkbox-label {
      margin-left: 9px;
    }
  }

  &.label-margin-12 {
    .p-checkbox-label {
      margin-left: 12px;
    }
  }

  &.h {
    &--20 {
      @extend .h;
      .p-checkbox {
        height: 20px;
        width: 20px;
      }

      .p-checkbox-box {
        height: 20px;
        width: 20px;
      }
    }
  }
}
