@import '../breakpoints';
@import '../_shared';

p-toast {
  .p-toast {
    width: fit-content;
    max-width: 100%;
    min-width: 400px;
  }

  .p-toast-message.sw-update {
    background: white;
  }
}

p-toastitem {
  .p-toast-message-content {
    align-items: center;
    padding: 20px 24px;
  }

  .p-toast-message-icon {
    font-size: 20px;
    display: flex;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .p-toast-detail {
    margin-top: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    @include switzerFont();
  }

  .p-toast-summary {
    font-size: 14px;
    font-weight: 700;
    @include switzerFont();
  }

  .p-toast-message-success {
    border-color: var(--color-success-500);
    background-color: var(--color-success-50);
    color: var(--color-success-500);
    .p-icon {
      color: var(--color-success-500);
    }
  }

  .p-toast-message-warn {
    border-color: var(--color-secondary-500);
    background-color: var(--color-secondary-50);
    color: var(--color-secondary-500);
    .p-icon {
      color: var(--color-secondary-500);
    }
  }

  .p-toast-message-error {
    border-color: var(--color-alert-500);
    background-color: var(--color-alert-50);
    color: var(--color-alert-500);
    .p-icon {
      color: var(--color-alert-500);
    }
  }
}

@include mobile {
  p-toast {
    .p-toast {
      top: 0;
      right: 0;
      width: 100%;
      min-width: unset;

      .p-toast-message {
        border-radius: 0;
      }
    }
  }

  p-toastitem {
    .p-toast-message-content {
      align-items: center;
      padding: 16px 16px 16px 10px;
    }
    .p-toast-icon-close {
      width: 24px;
      height: 24px;
    }
  }
}
