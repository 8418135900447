@import '../breakpoints';
@import '../_shared';

p-progressBar {
  width: 100%;
}

.p-progressbar {
  height: 6px;
  width: 100%;
}

