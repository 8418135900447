@import '../breakpoints';
@import '../_shared';

p-accordion {
  --accordion__bg-color: var(--color-gray-50);
  --accordion__hover-bg-color: var(--color-gray-100);
  --accordion__border-color: var(--color-gray-200);
  --accordion__hover-border-color: var(--color-gray-50);
  --accordion__color: var(--color-gray-900);
  --accordion__hover-color: var(--color-gray-900);

  &.chevron-large {
    .p-accordion-toggle-icon {
      svg {
        width: 14px;
      }
    }
  }

  &.chevron-small {
    .p-accordion-toggle-icon {
      svg {
        width: 10px;
      }
    }
  }

  &.chevron-right {
    .p-accordion-header-link {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
    .p-accordion-toggle-icon {
      margin: 0;
    }
  }

  &.expand-chevron-left {
    .p-accordion-header-link {
      chevronrighticon {
        transform: rotate(180deg);
      }
    }
  }

  &.toggle-icon-fit {
    &.chevron-small {
      .p-accordion-toggle-icon {
        width: 14px;
      }
    }
    &.chevron-large {
      .p-accordion-toggle-icon {
        width: 10px;
      }
    }
  }

  &.no-chevron-opened {
    .p-accordion-header-link {
      &[aria-expanded='true'] {
        .p-accordion-toggle-icon {
          display: none;
        }
      }
    }
  }

  &.no-border-bottom {
    .p-accordion-header-link {
      &[aria-expanded='true'] {
        border-bottom-color: var(--accordion__bg-color) !important;
      }
    }

    .p-accordion-header {
      &:hover {
        .p-accordion-header-link {
          &[aria-expanded='true'] {
            border-bottom-color: var(--accordion__hover-bg-color) !important;
          }
        }
      }
    }
  }

  &.borderless {
    .p-accordion-header-link {
      border-color: var(--accordion__bg-color);
      --accordion__hover-border-color: var(--accordion__bg-color);
      --accordion__border-color: var(--accordion__hover-bg-color);
    }
    .p-accordion-content {
      border-color: var(--accordion__bg-color);
      --accordion__hover-border-color: var(--accordion__bg-color);
      --accordion__border-color: var(--accordion__hover-bg-color);
    }
  }

  &.hover-disabled {
    .p-accordion-header {
      &:hover {
        .p-accordion-header-link {
          background-color: var(--accordion__bg-color);
          border-color: var(--accordion__hover-border-color);
          color: var(--accordion__color);
        }
      }
    }
  }

  &.inherit-content-color {
    .p-accordion-content {
      background-color: var(--accordion__bg-color);
    }
  }

  &.flexible {
    display: flex;

    .p-accordion {
      display: flex;
      width: 100%;
    }
    p-accordiontab {
      display: flex;
      width: 100%;

    }
    .p-accordion-tab {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .p-toggleable-content {
      display: flex;
    }
    .p-accordion-content {
      display: flex;
      width: 100%;
    }
  }

  .p-accordion-header {
    &:hover {
      .p-accordion-header-link {
        background-color: var(--accordion__hover-bg-color);
        border-color: var(--accordion__border-color);
        color: var(--accordion__hover-color);
      }
    }

    &:not(:hover) {
      .p-accordion-header-link {
        background-color: var(--accordion__bg-color);
        border-color: var(--accordion__border-color);
        color: var(--accordion__color);
      }
    }
  }

  .p-accordion-header-link {
    padding: 8px 16px;
  }

  &.medium {
    .p-accordion-header-link {
      padding: 10px 16px;
    }
  }

  &.large {
    .p-accordion-header-link {
      padding: 12px 16px;
    }
  }

  .p-accordion-header-link {
    display: flex;
    flex-direction: row;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .p-accordion-header-link {
    &:not([aria-expanded='true']) {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .p-accordion-content {
    padding: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
    border-color: var(--accordion__border-color);
  }

  .p-accordion-toggle-icon {
    display: flex;
    width: 22px;
    justify-content: center;
    margin-right: 4px;
  }

  .p-accordion-toggle-icon {
    svg {
      color: var(--color-gray-500);
      width: 12px;
    }
  }

  .p-accordion-tab {
    overflow: hidden;
  }
}
