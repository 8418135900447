@mixin scrollbarGray {
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-corner {
    transition: opacity 0.3s linear;
  }

  &.invisible {
    &::-webkit-scrollbar {
      // for iOS
      display: none;
      width: 0;
      height: 0;
      background: transparent;
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-gray-300);
    border-radius: 85px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 85px;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: var(--color-gray-300);
    }

    &::-webkit-scrollbar-track {
      opacity: 0.25;
    }
  }

  &:active {
    &::-webkit-scrollbar-thumb {
      opacity: 1;
      background: var(--color-gray-300);
    }

    &::-webkit-scrollbar-track {
      opacity: 1;
    }
  }
}

@mixin switzerFont {
  font-family: 'Switzer', sans-serif;
}

@keyframes slideUp {
  from {
    bottom: -100%;
  }
  to {
    bottom: 0;
  }
}
