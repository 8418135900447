@import '../breakpoints';
@import '../_shared';

p-tag {
  --text-color: var(--color-gray-500);
  --border-color: var(--color-gray-500);
  --background-color: var(--color-gray-100);
  --font-size: 12px;
  font-weight: 400;

  &.video {
    .p-tag {
      min-width: 160px;
      font-size: 14px;
    }
  }

  &.clickable {
    .p-tag {
      &:hover {
        border-color: var(--border-color);
      }
    }
  }

  .p-tag {
    color: var(--text-color);
    background-color: var(--background-color);
    border: 1px solid var(--background-color);
    font-weight: inherit;
    font-size: var(--font-size);
    padding: 3px 5px;
    @include switzerFont;
  }

  &.warning {
    --text-color: var(--color-secondary-500);
    --border-color: var(--color-secondary-500);
    --background-color: var(--color-secondary-100);
  }

  &.info {
    --text-color: var(--color-gray-900);
    --border-color: #F6CE00;
    --background-color: #F6CE00;
  }

  &.alert {
    --text-color: var(--color-alert-500);
    --border-color: var(--color-alert-500);
    --background-color: var(--color-alert-100);
  }

  &.success {
    --text-color: var(--color-success-500);
    --border-color: var(--color-success-500);
    --background-color: var(--color-success-100);
  }

  &.purple {
    --text-color: var(--color-primary-500);
    --border-color: var(--color-primary-500);
    --background-color: var(--color-primary-100);
  }

  &.h--22 {
    display: flex;
    height: 22px;

    .p-tag {
      display: flex;
      height: 22px;
    }
  }

  &.fw-600 {
    .p-tag {
      font-weight: 600;
    }
  }

  &.p-2-6 {
    .p-tag {
      padding: 2px 6px;
    }
  }

  &.p-2-8 {
    .p-tag {
      padding: 2px 8px;
    }
  }

  &.p-4-6 {
    .p-tag {
      padding: 4px 6px;
    }
  }

  &.p-3-12-16 {
    .p-tag {
      padding: 3px 12px 3px 16px;
    }
  }

  &.r-4 {
    .p-tag {
      border-radius: 4px;
    }
  }

  &.r-6 {
    .p-tag {
      border-radius: 6px;
    }
  }

  &.fs-14 {
    .p-tag {
      font-size: 14px;
    }
  }
}

