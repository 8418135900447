@import '../breakpoints';
@import '../_shared';

.p-breadcrumb {
  @include switzerFont;

  border: none;
  padding: 16px 32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-gray-900);

  .p-menuitem-link {
    .p-menuitem-text {
      color: var(--color-gray-500);
    }
    &.p-menuitem-link-active {
      .p-menuitem-text {
        color: var(--color-gray-900);
      }
    }
  }

  .p-menuitem-link-active {
    .p-menuitem-text {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .p-menuitem-text {
    color: var(--color-gray-900);
  }

  .p-menuitem-separator {
    margin-left: 4px;
    margin-right: 4px;
  }
}

@include mobile {
  .p-breadcrumb {
    padding: 16px 12px;
  }
}
