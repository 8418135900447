@import "libs/web/src/ui-kit/styles/reset.scss";

@import "primeng/resources/themes/lara-light-purple/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

@import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
@import "maplibre-gl/src/css/maplibre-gl.css";

@import "libs/web/src/ui-kit/styles/colors.scss";
@import "libs/web/src/ui-kit/styles/typography.scss";
@import "libs/web/src/ui-kit/styles/layout.scss";
@import "libs/web/src/ui-kit/styles/prime-ng.scss";
@import "libs/web/src/ui-kit/styles/map.scss";
@import "libs/web/src/ui-kit/styles/breakpoints.scss";

#sentry-feedback {
  --inset: 0 0 0 auto;
}
