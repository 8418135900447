@import '../breakpoints';
@import '../_shared';

p-iconfield {
  display: block;
  --icon-padding: 10px;
  --icon-right-padding: 10px;
  --icon-left-padding: 10px;

  .p-input-icon {
    margin-top: 0;
    transform: translateY(-50%);
  }

  &:has(.p-icon-field-left) {
    --icon-right-padding: 10px;
    --icon-left-padding: 40px;

    .p-input-icon {
      left: 10px;
    }

    .p-autocomplete-input {
      padding-left: var(--icon-left-padding);
    }

    input {
      padding-left: var(--icon-left-padding);
      padding-right: var(--icon-right-padding);
    }
  }

  &:has(.p-icon-field-right) {
    --icon-right-padding: 40px;
    --icon-left-padding: 10px;

    .p-input-icon {
      right: 10px;
    }

    input {
      padding-left: var(--icon-left-padding);
      padding-right: var(--icon-right-padding);
    }
  }

  &.h {
    --icon-padding: 40px;

    &--34 {
      height: 34px;
      @extend .h;

      input {
        height: 34px;
      }
    }

    &--38 {
      height: 38px;
      @extend .h;

      input {
        height: 38px;
      }
    }

    &--40 {
      height: 40px;
      @extend .h;

      input {
        height: 40px;
      }
    }
  }

  &.edit-device-name {
    width: 100%;

    input {
      width: 100%;
      padding: 14px 38px 14px 8px;
      height: 36px;
      font-size: 14px;
    }

    p-inputIcon {
      .p-input-icon {
        color: var(--color-success-500);

        &.disabled {
          color: var(--color-gray-300);
          cursor: not-allowed;
        }
      }
    }
  }

  &:has(input[disabled]) {
    p-inputIcon {
      &:last-child {
        .p-input-icon {
          opacity: 0.4;
        }
      }
    }
  }

  .p-icon-field {
    display: block;

    &:has(> p-inputIcon + input + p-inputIcon) {
      --icon-right-padding: var(--icon-left-padding);

      p-inputIcon {
        &:last-child {
          .p-input-icon {
            right: 10px;
            left: unset;
          }
        }
      }
    }

    &:has(> p-inputIcon + p-autocomplete + p-inputIcon) {
      --icon-right-padding: var(--icon-left-padding);

      p-inputIcon {
        &:last-child {
          .p-input-icon {
            right: 10px;
            left: unset;
          }
        }
      }
    }

    p-autocomplete {
      &:has(.p-icon-wrapper) {
        --icon-right-padding: var(--icon-left-padding);
      }
    }
  }

  .p-icon-field-right {
    .p-input-icon {
      &.disabled {
        color: var(--color-gray-300);
      }
    }
  }
}

@include mobile {
  p-iconField {
    &.search {
      input {
        height: 40px;
      }
    }
  }
}
