@import 'breakpoints';

.full-screen-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.transparent-block {
  opacity: 0 !important;
}

@supports (height: 100dvh) {
  .full-screen-container {
    height: 100dvh;
  }
}

.left-panel {
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 580px;
  padding: 32px 32px 0 32px;
  flex-grow: 1;
  overflow: hidden;
}

.right-panel {
  flex: 3;
  height: 100%;
  position: sticky;
  top: 0;
}

.map-content {
  height: 100%;
}

.stats-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  margin-right: 8px;
  padding: 8px 12px;
  border-radius: 6px;
  background-color: var(--color-gray-100);
  --icon-color: var(--color-gray-500);
  --text-color: var(--color-gray-900);

  &.green {
    --text-color: var(--color-success-500);
    --icon-color: var(--color-success-500);
  }

  &.red {
    --text-color: var(--color-alert-500);
    --icon-color: var(--color-alert-500);
  }

  &.gray {
    .text {
      color: var(--color-gray-500);
    }
  }

  .text {
    color: var(--text-color);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  i {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }

  &:last-child {
    margin-right: 0;
  }
}

.vertical-spacer {
  background-color: var(--color-gray-300);
  height: 20px;
  width: 1px;

  &--8 {
    @extend .vertical-spacer;
    margin-right: 8px;
    margin-left: 8px;
  }

  &--12 {
    @extend .vertical-spacer;
    margin-right: 12px;
    margin-left: 12px;
  }

  &--16 {
    @extend .vertical-spacer;
    margin-right: 16px;
    margin-left: 16px;
  }
}

.horizontal-spacer {
  min-height: 1px;
  max-height: 1px;
  height: 1px;
  width: 100%;
  background-color: var(--color-gray-200);

  &--12 {
    @extend .horizontal-spacer;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  &--16 {
    @extend .horizontal-spacer;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &--24 {
    @extend .horizontal-spacer;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &--32 {
    @extend .horizontal-spacer;
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

.logo-small {
  display: none;
  width: 45px;
  height: 55px;
  margin-right: auto;
  margin-left: auto;

  img {
    max-height: 100%;
    object-fit: contain;
  }
}

.logo-large {
  display: block;
  width: 235px;

  &.logo-55 {
    height: 55px;
  }

  &.logo-48 {
    height: 48px;
  }

  img {
    max-height: 100%;
    object-fit: contain;
  }
}

.logo-mobile {
  display: none;
  height: 40px;

  img {
    max-height: 100%;
    object-fit: contain;
  }
}

.avatar {
  --icon-color: var(--color-primary-500);
  --background: var(--color-primary-100);
  --avatar-size: 44px;

  position: relative;
  overflow: hidden;
  min-width: var(--avatar-size);
  min-height: var(--avatar-size);
  max-width: var(--avatar-size);
  max-height: var(--avatar-size);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: var(--background);

  &:has(.bg),
  &:has(img) {
    --background: transparent;
  }

  .bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0.16;
  }

  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }

  &--40 {
    @extend .avatar;
    --avatar-size: 40px;
    border-radius: 50%;
  }

  &--48 {
    @extend .avatar;
    --avatar-size: 48px;
  }

  &--56 {
    @extend .avatar;
    --avatar-size: 56px;
    border-radius: 50%;
  }

  &--64 {
    @extend .avatar;
    --avatar-size: 64px;
    border-radius: 50%;
  }
}

.flex {
  display: flex;

  &--col {
    @extend .flex;
    flex-direction: column;
  }

  &--row {
    @extend .flex;
    flex-direction: row;
  }

  &.gap-8 {
    gap: 8px;
  }

  &.gap-12 {
    gap: 12px;
  }

  &.gap-16 {
    gap: 16px;
  }
}

.scroll-wrapper {
  overflow-y: auto;
}

.scrollable {
  margin-right: -12px;
  padding-right: 6px;
}

.scrollbar-gray {
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-corner {
    transition: opacity 0.3s linear;
  }

  &.invisible {
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-gray-300);
    border-radius: 85px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 85px;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: var(--color-gray-300);
    }

    &::-webkit-scrollbar-track {
      opacity: 0.25;
    }
  }

  &:active {
    &::-webkit-scrollbar-thumb {
      opacity: 1;
      background: var(--color-gray-300);
    }

    &::-webkit-scrollbar-track {
      opacity: 1;
    }
  }
}

.drag-group {
  padding: 4px;
  cursor: grab;

  .drag-panel {
    width: 40px;
    height: 5px;
    background-color: var(--color-gray-300);
    border-radius: 2px;
  }
}

.show-on {
  &--desktop {
    display: block;
  }

  &--mobile {
    display: none;
  }

  &--mobile-small {
    display: none;
  }
}

.hide-on {
  &--desktop {
    display: none;
  }

  &--mobile {
    display: block;
  }

  &--mobile-small {
    display: block;
  }
}

// TODO: Move away
.indicator-skeleton {
  width: 32%;
  height: 66px;

  &:nth-child(3n) {
    margin-right: 0;
  }
}

.not-allowed {
  opacity: 0.5;
  pointer-events: none;
}

.clear-input-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transform-origin: center;
  z-index: 1;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-50%) scale(1.2);
  }

  &--18 {
    @extend .clear-input-button;
    right: 18px;
  }

  &--12 {
    @extend .clear-input-button;
    right: 12px;
  }
}

.tsp-admin-header {
  padding: 24px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.bordered {
    border-bottom: 1px solid var(--color-gray-200);
  }

  .heading-group {
    align-items: center;

    h1 {
      margin-right: 16px;
      line-height: 40px;
    }
  }

  .controls {
    display: flex;
    align-items: center;
    flex-grow: 1;
    max-width: 600px;
  }

  .search-container {
    flex-grow: 1;
    position: relative;
    margin-right: 12px;
  }

  .separator {
    margin: 0 12px;
  }

  .search-input {
    color: var(--color-gray-500);
  }
}

form,
.form {
  &.medium {
    padding: 24px;
  }

  &.p-t-16 {
    padding-top: 16px;
  }

  p-dropdown {
    --text-color: var(--color-gray-900);
  }

  p-inputmask {
    --text-color: var(--color-gray-900);
  }

  .label {
    color: var(--color-gray-900);
    display: flex;
  }

  .hint-text {
    color: var(--color-gray-500);
  }

  .required {
    margin-left: 8px;
    color: var(--color-alert-500);
  }

  .errors-box {
    margin: 10px 0;
  }

  .error-message {
    color: red;
    font-size: 14px;

    & + .error-message {
      margin-top: 4px;
    }
  }

  .field-double {
    display: flex;
    gap: 16px;
  }
}

input {
  &.p-inputtext {
    color: var(--color-gray-900);
    border-radius: 6px;
    padding: 10px;
    @include switzerFont();

    &::placeholder {
      color: var(--color-gray-500);
    }
  }

  &.inline {
    height: unset;
  }

  &.ng-invalid.ng-touched {
    border: 1px solid var(--color-alert-500);
  }

  font-size: 14px;
  line-height: 20px;
  height: 40px;

  &.h {
    font-size: 14px;
    line-height: 20px;

    &--34 {
      @extend .h;
      height: 34px;
    }

    &--38 {
      @extend .h;
      height: 38px;
    }

    &--40 {
      @extend .h;
      height: 40px;
    }

    &--48 {
      @extend .h;
      font-size: 16px;
      line-height: 24px;
      height: 48px;
    }
  }
}

.errors-section {
  background-color: var(--color-error-bg);
  color: var(--color-error);
  padding: 10px;
  border-radius: 6px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-items: start;
  align-items: center;

  .errors-section-icon {
    display: flex;
    align-items: center;
    margin-right: 10px;

    i {
      margin-bottom: -2px;
    }

    .pi {
      font-size: 14px;
    }
  }
}

.icon-check-container {
  width: 54px;
  height: 54px;
  min-width: 54px;
  min-height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--color-success-500);
}

.option-item {
  display: flex;
  align-items: center;

  &.p-10-18 {
    padding: 10px 18px;
  }

  &.p-10-12 {
    padding: 10px 12px;
  }

  &.has-bottom-border {
    position: relative;

    &::after {
      content: '';
      display: block;
      border-bottom: 1px solid var(--color-gray-200);
      width: 100%;
      position: absolute;
      bottom: -4px;
      left: 0;
    }

    margin-bottom: 8px;
  }

  &.has-border-top {
    position: relative;

    &::after {
      content: '';
      display: block;
      border-bottom: 1px solid var(--color-gray-200);
      width: 100%;
      position: absolute;
      top: -4px;
      left: 0;
    }

    margin-top: 8px;
  }

  .icon-wrapper {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    display: flex;

    i {
      font-size: 14px;
    }
  }

  .text-wrapper {
    color: var(--color-gray-500);

    &.centered {
      margin: 0 auto;
    }
  }
}

.main-table-wrapper {
  width: 100%;
  height: 100%;
  max-height: 100%;
  flex-grow: 1;
  min-width: 0;
  min-height: 0;
  position: relative;
}

.table-wrapper {
  max-height: 100%;
  // position: relative;
  overflow: auto;
  padding: 0 32px 32px 32px;

  &.flex-grow-1 {
    flex-grow: 1;
  }
}

.table-footer {
  align-items: center;
  display: flex;
  justify-content: center;
}

.m-0 {
  margin: 0 !important;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-6 {
  margin-bottom: 6px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-4 {
  margin-right: 4px;
}

.mr-6 {
  margin-right: 6px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-10 {
  margin-right: 8px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-16 {
  margin-right: 16px;
}

.mt-16 {
  margin-top: 16px;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-4 {
  margin-left: 4px;
}

.ml-6 {
  margin-left: 6px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-16 {
  margin-left: 16px;
}

.p-16 {
  padding: 16px;
}

.w100 {
  width: 100%;
}

.field {
  &.with-hint {
    position: relative;
    height: fit-content;

    &:has(input[disabled]) {
      &::after {
        opacity: 0.4;
      }
    }

    &::after {
      @include switzerFont;
      content: attr(data-hint);
      color: var(--color-gray-500);
      position: absolute;
      font-size: 14px;

      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }

    input {
      padding-right: 50px;
    }
  }
}

.counter {
  border-radius: 6px;
  padding: 4px 6px;
  background-color: var(--color-primary-100);
  color: var(--color-primary-400);
  --icon-color: var(--color-primary-400);

  i {
    margin-right: 6px;
  }

  align-items: center;
}

.counter-compact {
  width: 29px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  i {
    margin-right: 0 !important;
  }
}

.clickable {
  cursor: pointer;
}

.w-250 {
  max-width: 250px;
  min-width: 250px;
}

.w-100 {
  max-width: 100px;
  min-width: 100px;
}

.has-bottom-border {
  position: relative;

  &::after {
    content: '';
    display: block;
    border-bottom: 1px solid var(--color-gray-200);
    width: 100%;
    position: absolute;
    bottom: -4px;
    left: 0;
  }

  margin-bottom: 8px;
}

.has-top-border {
  position: relative;

  &::before {
    content: '';
    display: block;
    border-bottom: 1px solid var(--color-gray-200);
    width: 100%;
    position: absolute;
    top: -4px;
    left: 0;
  }

  margin-top: 8px;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.fw-btn {
  width: 100%;

  .p-button {
    width: 100%;
  }
}

.header-card {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 10px;
  height: 48px;
  border-radius: 8px;
  border: 1px solid var(--color-gray-200);

  .alert-data-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
  }

  .text {
    color: var(--color-gray-800);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .badge {
    width: fit-content;
    height: 16px;
    padding: 2px 4px;
    border-radius: 4px;
    letter-spacing: 1px;
    text-transform: uppercase;
    background-color: var(--color-gray-100);
    color: var(--color-gray-400);
  }
}

.no-results-found-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;

  .title {
    color: var(--color-gray-900);
    margin-bottom: 4px;
  }

  .description {
    color: var(--color-gray-500);
  }
}

.alert-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 28px;
  min-height: 28px;
  color: var(--color-gray-0);
  border: 2px solid var(--color-gray-0);
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.4);

  &.purple {
    background-color: var(--color-primary-500);
  }

  &.red {
    background-color: var(--color-alert-500);
  }
}

.metadata {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  margin-bottom: 8px;

  .row {
    padding: 4px 0;
    display: flex;
    align-items: flex-start;
    gap: 8px;

    color: var(--color-gray-600);
    --icon-color: var(--color-gray-600);
  }
}

.camera-offline {
  display: block;
  margin-bottom: 8px;

  &.color-custom {
    --accordion__bg-color: var(--color-secondary-50);
    --accordion__hover-bg-color: var(--color-secondary-100);
    --accordion__border-color: var(--color-secondary-200);
    --accordion__hover-border-color: var(--color-secondary-300);

    --accordion__color: var(--color-gray-700);
    --accordion__hover-color: var(--color-gray-800);
  }

  .content {
    padding: 0 16px 12px 45px;

    .retrieval {
      display: block;
      margin-bottom: 14px;
    }

    span {
      color: var(--color-gray-700);
    }
  }
}

.video-overlay-button {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  backdrop-filter: blur(2px);
  border: 2px solid #F2F4F7CC;
  background-color: #F2F4F773;

  .figure {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &.start {
    .figure {
      width: 0;
      height: 0;
      transform: translate(-35%, -50%);
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 10px solid var(--color-gray-0);
    }
  }

  &.stop {
    .figure {
      width: 16px;
      height: 16px;
      transform: translate(-50%, -50%);
      border-radius: 2px;
      background-color: var(--color-gray-0);
    }
  }

  &.pause {
    .figure {
      width: 10px;
      transform: translate(-50%, -50%);

      &::before {
        position: absolute;
        content: '';
        display: block;
        width: 3px;
        height: 16px;
        transform: translateY(-50%);
        background-color: var(--color-gray-0);
        left: 0;
      }

      &::after {
        position: absolute;
        content: '';
        display: block;
        width: 3px;
        height: 16px;
        transform: translateY(-50%);
        background-color: var(--color-gray-0);
        right: 0;
      }
    }
  }

  &.number {
    .figure {
      font-size: 14px;
      font-weight: 600;
      color: var(--color-gray-0);
      transform: translate(-50%, -50%);

      @include switzerFont();
    }
  }

  &.right {
    right: 15%;
    transform: translateY(-50%);
    left: unset;
  }

  &.left {
    left: 15%;
    transform: translateY(-50%);
    right: unset;
  }
}

.gray-badge {
  width: fit-content;
  height: 16px;
  padding: 2px 4px;
  border-radius: 4px;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: var(--color-gray-100);
  color: var(--color-gray-400);

  &.map {
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    color: var(--color-gray-500);
    text-transform: unset;
    height: unset;
    padding: 2px 8px;
  }
}

.vehicle-selector-item {
  display: flex;
  align-items: center;
  max-width: 200px;

  .avatar {
    margin-right: 8px;
    --avatar-size: 36px;

    .bg {
      opacity: 1;
    }

    .icon {
      z-index: 1;
      --icon-color: var(--color-gray-0);
    }
  }

  .caption {
    color: var(--color-gray-900);
  }

  .description {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 18px;
    color: var(--color-gray-500);
  }
}

.error-page {
  background-color: var(--color-primary-25);
  display: flex;
  align-items: center;
  justify-content: center;

  .content-wrapper {
    max-width: 585px;
    width: fit-content;
    padding: 96px;
    border: 1px solid var(--color-gray-200);
    background-color: var(--color-gray-25);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 8px;
  }

  .illustration {
    margin-bottom: 32px;
  }

  svg {
    max-width: 250px;
    height: fit-content;
  }

  h1 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 16px;
    text-align: center;
    @include switzerFont();
  }

  h3 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 32px;
    text-align: center;
    @include switzerFont();
  }

  .flex {
    gap: 16px;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@-moz-document url-prefix() {
  .scrollbar-gray {
    scrollbar-width: thin;
    scrollbar-color: var(--color-gray-300) transparent;
  }
}

@include tablet {
  .error-page {
    svg {
      max-width: 190px;
    }
  }
}

@include tabletSmall {
  .left-panel {
    max-width: unset;
  }
  .error-page {
    .content-wrapper {
      max-width: calc(100% - 48px);
      width: 585px;
      padding: 96px 72px;
    }
  }
}

@include mobile {

  .error-page {
    h1 {
      font-size: 24px;
    }

    h3 {
      font-size: 14px;
    }

    .content-wrapper {
      padding: 96px 57px;
    }
  }

  .table-wrapper {
    padding: 0 16px 16px 16px;
  }

  .show-on {
    &--desktop {
      display: none;
    }

    &--mobile {
      display: block;
    }
  }

  .hide-on {
    &--mobile {
      display: none;
    }
  }

  .backdrop {
    // Visible state
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #10182899;
    backdrop-filter: blur(10px);
    z-index: 9;
    opacity: 1;
    transition: background-color var(--animation-speed) ease,
    backdrop-filter var(--animation-speed) ease,
    opacity var(--animation-speed) ease,
    visibility var(--animation-speed) ease;

    &.hidden {
      // Initial state
      visibility: hidden;
      background-color: rgba(0, 0, 0, 0);
      backdrop-filter: blur(0px);
      opacity: 0;
    }
  }
}

@include mobileSmall {
  .show-on {
    &--mobile-small {
      display: block;
    }
  }

  .hide-on {
    &--mobile-small {
      display: none;
    }
  }


  .error-page {
    .content-wrapper {
      padding: 96px 24px;
      max-width: calc(100% - 32px);
    }
  }
}
