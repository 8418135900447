@import './prime-ng/accordion';
@import './prime-ng/autocomplete';
@import './prime-ng/breadcrumb';
@import './prime-ng/button';
@import './prime-ng/checkbox';
@import './prime-ng/dialog';
@import './prime-ng/dropdown';
@import './prime-ng/datepicker';
@import './prime-ng/iconfield';
@import './prime-ng/inputmask';
@import './prime-ng/menu';
@import './prime-ng/menubar';
@import './prime-ng/messages';
@import './prime-ng/multiselect';
@import './prime-ng/overlaypanel';
@import './prime-ng/panel';
@import './prime-ng/progressbar';
@import './prime-ng/radiobutton';
@import './prime-ng/table';
@import './prime-ng/tag';
@import './prime-ng/toast';
@import './prime-ng/tooltip';

p-progressSpinner {
  &.primary {
    background-color: transparent;

    .p-progress-spinner-circle {
      stroke: var(--color-primary-500) !important;
    }
  }

  &.secondary {
    background-color: transparent;

    .p-progress-spinner-circle {
      stroke: var(--color-gray-500) !important;
    }
  }
}
