@import '../breakpoints';
@import '../_shared';

p-messages {
  z-index: 12000 !important;

  &.inline {
    .p-message {
      margin: 0;
      border-radius: 0;

      .p-message-icon {
        .p-icon-wrapper svg {
          width: 16px;
          height: 16px;
        }
      }

      .p-message-wrapper {
        height: 63px;
        padding: 17.5px 24.5px;

        .p-message-summary {
          font-family: var(--primary-font), sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .p-message-detail {
          font-family: var(--primary-font), sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .p-message-close {
        .p-icon-wrapper svg {
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  // Custom colors
  .p-message {
    --p-message-color: var(--color-primary-500);
    --p-message-bg-color: var(--color-primary-100);

    color: var(--p-message-color);
    border-color: var(--p-message-color);
    background-color: var(--p-message-bg-color);

    .p-message-icon,
    .p-message-close {
      color: var(--p-message-color);
    }

    &.p-message-warn {
      --p-message-color: var(--color-secondary-500);
      --p-message-bg-color: var(--color-secondary-100);
    }


    &.p-message-error {
      --p-message-color: var(--color-alert-500);
      --p-message-bg-color: var(--color-alert-100);
    }

    &.p-message-success {
      --p-message-color: var(--color-success-500);
      --p-message-bg-color: var(--color-success-100);
    }
  }
}
